import { AdminActionTypes } from "../constants/actionTypes";

const initialState = {
 token: "",
};

export const adminReducer = (state = initialState, action) => {
 switch (action.type) {
  case AdminActionTypes.SET_TOKEN:
   return {
    ...state,
    token: action.payload,
   };
  case AdminActionTypes.LOGOUT: {
   return {
    token: ""
   };
  }
  default:
   return state;
 }
};
