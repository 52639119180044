import React from "react";
import { Link, useLocation } from "react-router-dom";

function ClientDetails() {
  const location = useLocation();
  const { client } = location.state || {};
  return (
    <div className="dashboard-section admin-security-settings">
      <div
        className="d-flex gap-2 flex-wrap justify-content-between"
        style={{ padding: "19px 24px", marginBottom: 5 }}
      >
        <h4
          className="text-nowrap"
          style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
        >
          CLIENT DETAILS
        </h4>
        <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
          <li className="fs-14 breadcrumb-item">
            <Link to="/admin/dashboard">Dashboard</Link>
          </li>
          <li className="fs-14 breadcrumb-item active">
            <Link to={"/admin/users"}>Back to Client Management</Link>
          </li>
        </ol>
      </div>
      <div className="section-spacing">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header ">
              <div className="row">
                <div className="col-lg-4 col-6">
                  <h5 className="card-title mb-0">Profile Image</h5>
                </div>
                <div className="col-lg-8 col-6">
                  <h5 className="card-title mb-0">User Details</h5>
                </div>
              </div>
            </div>
            <div className="card-body p-4">
              <div className="row">
                <div className="col-lg-4 col-12 text-center text-lg-left mb-4 mb-md-0">
                  <img
                    src="/assets/images/header-avatar.png"
                    width={"50%"}
                    alt=""
                    className="mx-auto d-block"
                  />
                </div>
                <div className="col-lg-4">
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-4">
                          <small className="fw-bold">User Name</small>
                        </div>
                        <div className="col-8">
                          <small className="text-danger ">
                            {client?.name || "N/A"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12 ">
                      <div className="row">
                        <div className="col-4">
                          <small className="fw-bold ">City</small>
                        </div>
                        <div className="col-8">
                          <small className="text-muted ">
                            {client?.city || "N/A"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12 ">
                      <div className="row">
                        <div className="col-4">
                          <small className="fw-bold">Joining Date</small>
                        </div>
                        <div className="col-8">
                          <small className="text-muted">
                            {" "}
                            {client?.createdAt || "N/A"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row mb-3">
                    <div className="col-lg-12 ">
                      <div className="row">
                        <div className="col-4">
                          <small className="fw-bold">Email</small>
                        </div>
                        <div className="col-8">
                          <small className="text-muted">
                            {client?.email || "N/A"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12 ">
                      <div className="row">
                        <div className="col-4">
                          <small className="fw-bold">Country</small>
                        </div>
                        <div className="col-8">
                          <small className="text-muted">
                            {client?.country || "N/A"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetails;
