import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllData } from "../../api/Api";
import { useSelector } from "react-redux";

function AdminDashboard() {
  const adminToken = useSelector((state) => state.adminData.token);
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({});

  useEffect(() => {
    const fetchDashboardData = async () => {
      const res = await getAllData(adminToken);
      if (res.status === "success") {
        setDashboardData(res.data);
      }
      setLoading(false);
    };

    fetchDashboardData();
    // eslint-disable-next-line
  }, []);

  return (
    <section className="dashboard-section admin-dashboard">
      <div
        className="d-flex gap-2 flex-wrap justify-content-between"
        style={{ padding: "19px 24px", marginBottom: 5 }}
      >
        <h4
          className="text-nowrap"
          style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
        >
          DASHBOARD
        </h4>
      </div>
      <div className="section-spacing">
        {loading ? (
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="col-xxl-12">
            <div className="row">
              <div className="col-lg-4">
                <div className="card mt-xxl-n5">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="card-title mb-0">Client Users</h5>
                    <span className="badge bg-primary-alt text-white">Total</span>
                  </div>
                  <div className="card-body p-3 d-flex justify-content-between align-items-end gap-2">
                    <div className="d-flex flex-column">
                      <h2 className="mb-0">{dashboardData.totalUsers}</h2>
                      <span className="text-muted fs-12">Users</span>
                    </div>
                    <button className="btn p-0">
                      <Link
                        to="/admin/users"
                        className="badge bg-success fs-6 text-white"
                      >
                        View
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card mt-xxl-n5">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="card-title mb-0">Designers</h5>
                    <span className="badge bg-primary-alt text-white">Total</span>
                  </div>
                  <div className="card-body p-3 d-flex justify-content-between align-items-end gap-2">
                    <div className="d-flex flex-column">
                      <h2 className="mb-0">{dashboardData.totalDesigners}</h2>
                      <span className="text-muted fs-12">Designers</span>
                    </div>
                    <button className="btn p-0">
                      <Link
                        to="/admin/designers"
                        className="badge bg-success fs-6 text-white"
                      >
                        View
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card mt-xxl-n5">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="card-title mb-0">Portfolio</h5>
                    <span className="badge bg-primary-alt text-white">Total</span>
                  </div>
                  <div className="card-body p-3 d-flex justify-content-between align-items-end gap-2">
                    <div className="d-flex flex-column">
                      <h2 className="mb-0">{dashboardData.totalPortfolios}</h2>
                      <span className="text-muted fs-12">Portfolio</span>
                    </div>
                    <button className="btn p-0">
                      <Link
                        to="/admin/portfolio"
                        className="badge bg-success fs-6 text-white"
                      >
                        View
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default AdminDashboard;
