import React, { useState } from "react";
import Loader from "../../components/common/Loader";

function DeletePortfolioDialog({ id, handlePortfolioDelete }) {
 const [loading, setLoading] = useState();

 return (
  <>
   <div className="modal fade" id="delete-portfolio-dialog" tabIndex="-1" aria-labelledby="delete-portfolio-dialogLabel" aria-hidden="true">
    <div className="modal-dialog">
     <div className="modal-content">
      <div className="modal-body text-start">
       Are you sure you want to delete the portfolio?
      </div>
      <div className="modal-footer">
       <button type="button" className="btn btn-sm btn-primary" data-bs-dismiss="modal">Close</button>
       <button type="button" className="btn btn-sm btn-danger" onClick={async () => {
        setLoading(true);
        await handlePortfolioDelete(id);
        setLoading(false);
        const modal = document.getElementById("delete-portfolio-dialog");
        const modalInstance = window.bootstrap.Modal.getInstance(modal);
        modalInstance.hide();
       }}>{loading ? <Loader /> : "Delete"}</button>
      </div>
     </div>
    </div>
   </div>
  </>
 );
}

export default DeletePortfolioDialog;
