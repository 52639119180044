import React from "react";
import { Link, useLocation } from "react-router-dom";

function DesignerDetails() {
  const location = useLocation();
  const { designer } = location.state || {};
  return (
    <div className="dashboard-section admin-security-settings">
      <div
        className="d-flex gap-2 flex-wrap justify-content-between"
        style={{ padding: "19px 24px", marginBottom: 5 }}
      >
        <h4
          className="text-nowrap"
          style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
        >
          DESIGNER DETAILS
        </h4>
        <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
          <li className="fs-14 breadcrumb-item">
            <Link to="/admin/dashboard">Dashboard</Link>
          </li>
          <li className="fs-14 breadcrumb-item active">
            <Link to={"/admin/designers"}>Back to Designer Management</Link>
          </li>
        </ol>
      </div>
      <div className="section-spacing">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header ">
              <div className="row">
                <div className="col-lg-4 col-6">
                  <h5 className="card-title mb-0">Profile Image</h5>
                </div>
                <div className="col-lg-8 col-6">
                  <h5 className="card-title mb-0">User Details</h5>
                </div>
              </div>
            </div>
            <div className="card-body p-4">
              <div className="row">
                <div className="col-lg-4 col-12 text-center text-lg-left mb-4 mb-md-0">
                  <img
                    src="/assets/images/header-avatar.png"
                    width={"50%"}
                    alt=""
                    className="mx-auto d-block"
                  />
                </div>
                <div className="col-lg-4">
                  <div className="row mb-2">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold">Designer Name</small>
                        </div>
                        <div className="col-7">
                          <small className="text-danger ">{designer?.name || "N/A"}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold">Email</small>
                        </div>
                        <div className="col-7">
                          <small className="text-muted ">{designer?.email || "N/A"}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold">City</small>
                        </div>
                        <div className="col-7">
                          <small className="text-muted ">{designer?.city || "N/A"}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold">Pinterest</small>
                        </div>
                        <div className="col-7">
                          <small className="text-muted ">{designer?.portfolioSocials?.pinterest || "N/A"}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-12 ">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold ">Instagram</small>
                        </div>
                        <div className="col-7">
                          <small className="text-muted ">{designer?.portfolioSocials?.instagram || "N/A"}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-12 ">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold">Member Since</small>
                        </div>
                        <div className="col-7">
                          <small className="text-muted">{designer?.createdAt || "N/A"}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row mb-2">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold">User Name</small>
                        </div>
                        <div className="col-7">
                          <small className="text-danger ">{designer?.username || "N/A"}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold">Phone</small>
                        </div>
                        <div className="col-7">
                          <small className="text-muted ">{designer?.phoneNumber || "N/A"}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold">Country</small>
                        </div>
                        <div className="col-7">
                          <small className="text-muted ">{designer?.country || "N/A"}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-12 ">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold ">Facebook</small>
                        </div>
                        <div className="col-7">
                          <small className="text-muted ">{designer?.portfolioSocials?.facebook || "N/A"}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-12 ">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold ">Twitter</small>
                        </div>
                        <div className="col-7">
                          <small className="text-muted ">{designer?.portfolioSocials?.X || "N/A"}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-header mb-5 ">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <h5 className="card-title mb-2">About Designer</h5>
                </div>
                <div>{designer?.aboutMe || "N/A"}</div>
              </div>
            </div>
    
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesignerDetails;
