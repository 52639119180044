import React from "react";
import CardsExplore from "./CardsExplore";

function ExploreDesign() {
  return (
    <div className="container my-5">
      <div>
        <div className="text-center mb-5">
          <h1 className="">Explore Designs That Inspire</h1>
          <h6>
            Discover the elegance of creative expression, crafted with love and
            care.
          </h6>
        </div>
        <CardsExplore />
      </div>
    </div>
  );
}

export default ExploreDesign;
