import { toast } from "react-toastify";
import { createHeaders } from "./apiHelpers";

const BASE_URL = process.env.REACT_APP_API_URL;

// GET Requests
export async function get(endpoint, userToken, contentType = "urlencoded", controller) {
  const url = `${BASE_URL}/${endpoint}`;

  try {
    const headers = createHeaders(userToken, contentType);

    const response = await fetch(url, {
      method: "GET",
      headers: headers,
      signal: controller?.signal,
    });

    return response.json();
  } catch (error) {
    console.error("API GET request error:", error);
    toast.error("Failed to fetch data");
  }
}

// POST Requests
export async function post(
  endpoint,
  data,
  userToken,
  contentType = "urlencoded"
) {
  const url = `${BASE_URL}/${endpoint}`;

  try {
    const headers = createHeaders(userToken, contentType);

    let body;

    if (contentType === "json") {
      body = JSON.stringify(data);
    } else if (contentType === "formData") {
      body = data;
      delete headers["Content-Type"];
    } else {
      body = new URLSearchParams(data).toString();
    }

    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: body,
    });

    return response.json();
  } catch (error) {
    console.error("API POST request error:", error);
    toast.error("Failed to perform action");
  }
}

// PUT requests
export async function put(
  endpoint,
  data,
  userToken,
  contentType = "urlencoded"
) {
  const url = `${BASE_URL}/${endpoint}`;

  try {
    const headers = createHeaders(userToken, contentType);

    const body =
      contentType === "json"
        ? JSON.stringify(data)
        : new URLSearchParams(data).toString();

    const response = await fetch(url, {
      method: "PUT",
      headers: headers,
      body: body,
    });

    return response.json();
  } catch (error) {
    console.error("API PUT request error:", error);
    toast.error("Failed to update data");
  }
}

// DELETE requests
export async function del(endpoint, data, userToken, contentType = "urlencoded") {
  const url = `${BASE_URL}/${endpoint}`;

  try {
    const headers = createHeaders(userToken, contentType);

    const body =
    contentType === "json"
      ? JSON.stringify(data)
      : new URLSearchParams(data).toString();

    const response = await fetch(url, {
      body,
      method: "DELETE",
      headers
    });

    return response.json();
  } catch (error) {
    console.error("API DELETE request error:", error);
    toast.error("Failed to delete data");
  }
}
