import { AdminActionTypes } from "../constants/actionTypes";

export const setAdminToken = (data) => {
  return {
    type: AdminActionTypes.SET_TOKEN,
    payload: data,
  };
};

export const adminLogout = () => {
  return {
    type: AdminActionTypes.LOGOUT,
  };
};
