import React from "react";

function ProjectCard({ designer }) {
  return (
    <div className="col-md-8 order-1 order-md-1">
      <div className="text-center mb-5">
        <h4>{designer?.title}</h4>
        <p>
          By: <span className="text-main">{designer?.designerId?.name}</span>
        </p>
      </div>
      <div>
        <p className="my-4">
          <span style={{ fontSize: "14px" }}>{designer?.aboutMe}</span>
        </p>
      </div>
      <div className="text-center">
        <img src={designer?.img} width={"50%"} alt="Explore" />
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: designer?.description,
        }}
      />
      <div className="my-3">
        {designer?.images && designer?.images.length >= 3
          ? designer?.images.map((image, index) => {
              if (index % 3 === 0) {
                return (
                  <div className="row my-4" key={index}>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <img
                          src={designer?.images[index]}
                          width={"100%"}
                          alt="Card"
                        />
                      </div>
                      {designer?.images[index + 1] && (
                        <div className="mb-4 mb-md-0">
                          <img
                            src={designer?.images[index + 1]}
                            width={"100%"}
                            alt="Explore"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      {designer?.images[index + 2] && (
                        <div>
                          <img
                            src={designer?.images[index + 2]}
                            width={"100%"}
                            alt="Explore"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
              return null;
            })
          : ""}
        {designer?.videos &&
          designer?.videos?.map((video, index) => (
            <div key={index}>
              <video src={video} width="100%" height={450} controls muted />
            </div>
          ))}
      </div>
    </div>
  );
}

export default ProjectCard;
