import React from "react";

function Breadcrumb() {
  return (
    <div className="text-center mt-5">
      <span className="about-badge">Privacy Policy</span>
      <div className="display-5 mt-3">
      Demo App Privacy &  <br /> Policy
      </div>
      <div className="text-muted my-4">
      Committed to protect the privacy of its users
      </div>
    </div>
  );
}

export default Breadcrumb;
