import React, { useEffect, useState } from "react";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { Link } from "react-router-dom";
import { RiAddCircleLine } from "react-icons/ri";
import { useSidebarContext } from "../../contexts/dashboardContext";
import DesignerModal from "./DesignerModal";
import { categoryStatus, getAllCategory } from "../../api/Api";
import EditModal from "./EditModal";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

function DesignerCategories() {
  const [categoryRows, setCategoryRows] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [search, setSearch] = useState("");
  const { width } = useWindowSize();
  const { sidebarOpened } = useSidebarContext();

  const handleStatus = async (id) => {
    setLoading(true);

    try {
      const result = await categoryStatus({ id });
      if (result.status === "success") {
        toast.success(result.message || "Status updated successfully!");
        const res = await getAllCategory();
        if (res.status === "success") {
          setCategoryRows(res.data);
          setFilteredCategories(res.data);
        }
      } else {
        toast.error(result.message || "Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCategory = async () => {
      setLoading(true);
      const res = await getAllCategory();

      if (res?.status === "success") {
        setCategoryRows(res.data);
        setFilteredCategories(res.data);
      }
      setLoading(false);
    };

    fetchCategory();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const result = categoryRows.filter((category) => {
      return category.name.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredCategories(result);
  }, [search, categoryRows]);

  useEffect(() => {
    const dashboardLayout = document.querySelector(".dashboard-layout");
    const card = document.querySelector(".designer-categories-card");

    if (width < 768) {
      card.style.width = dashboardLayout.clientWidth - 39 + "px";
    } else if (!sidebarOpened) {
      card.style.width = dashboardLayout.clientWidth - 109 + "px";
    } else {
      card.style.width = dashboardLayout.clientWidth - 289 + "px";
    }
  }, [width, sidebarOpened]);

  const columns = [
    {
      name: "Sr #",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Created On",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-center gap-1">
          <button
            className="d-flex align-items-center gap-1 btn btn-sm btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdroptwo"
            onClick={() => setSelectedCategory(row)}
          >
            Edit
          </button>
          <button
            onClick={() => handleStatus(row._id)}
            className={`d-flex align-items-center gap-1 btn btn-sm ${
              row.isActive ? "btn-danger" : "btn-success"
            }`}
          >
            {row.isActive ? "Deactivate" : "Activate"}
          </button>
        </div>
      ),
      width: "fit-content",
    },
  ];

  return (
    <>
      <section className="dashboard-section designer-categories">
        <div
          className="d-flex gap-2 flex-wrap justify-content-between"
          style={{ padding: "19px 24px", marginBottom: 5 }}
        >
          <h4
            className="text-nowrap"
            style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
          >
            DESIGNER CATEGORIES
          </h4>
          <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
            <li className="fs-14 breadcrumb-item">
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li className="fs-14 breadcrumb-item active">
              Designer Categories
            </li>
          </ol>
        </div>
        <div className="section-spacing">
          <div className="card designer-categories-card">
            <div className="card-header d-flex flex-wrap gap-2 align-items-center justify-content-between">
              <h5
                className="card-title mb-0 flex-grow-1"
                style={{ minWidth: "fit-content" }}
              >
                Designer Categories
              </h5>
              <input
                type="text"
                className="form-control"
                placeholder="Search by Title"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ maxWidth: "250px", height: "28px" }}
              />
              <button
                style={{ fontSize: 12.25 }}
                className="d-flex align-items-center gap-1 btn btn-sm btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                <RiAddCircleLine size={24} />{" "}
                <span className="text-nowrap">Add Categories</span>
              </button>
            </div>
            <div className="card-body fs-14">
              <DataTable
                columns={columns}
                data={filteredCategories}
                progressPending={loading}
                pagination
                highlightOnHover
                striped
                responsive
                persistTableHead
              />
            </div>
          </div>
        </div>
      </section>
      <DesignerModal />
      <EditModal categoryName={selectedCategory} />
    </>
  );
}

export default DesignerCategories;
