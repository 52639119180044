import React from 'react'
import Navbar from '../components/common/landing/Navbar'
import Footer from '../components/common/landing/Footer'
import LoginScreen from '../components/Login/LoginScreen'

function DesignerLogin() {
  return (
    <div>
      <Navbar/>
      <LoginScreen/>
      <Footer/>
    </div>
  )
}

export default DesignerLogin
