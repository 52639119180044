import React from "react";
import Breadcrumb from "./Breadcrumb";

function Main() {
  return (
    <div className="container">
      <div className="mt-5 pt-3">
        <Breadcrumb />
        <div className="d-flex justify-content-center mt-5">
          <img
            className="w-75 about-img"
            src="assets/images/about.avif"
            alt="About Us"
          />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <span className="w-75">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam,
          itaque minus! Blanditiis sequi repudiandae, praesentium dignissimos,
          magnam qui, dolor nesciunt enim reiciendis magni veniam tenetur
          provident alias quisquam error quod.Lorem ipsum dolor, sit amet
          consectetur adipisicing elit. Ullam, itaque minus! Blanditiis sequi
          repudiandae, praesentium dignissimos, magnam qui, dolor nesciunt enim
          reiciendis magni veniam tenetur provident alias quisquam error
          quod.Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam,
          itaque minus! Blanditiis sequi repudiandae, praesentium dignissimos,
          magnam qui, dolor nesciunt enim reiciendis magni veniam tenetur
          provident alias quisquam error quod.
        </span>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <span className="w-75">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam,
          itaque minus! Blanditiis sequi repudiandae, praesentium dignissimos,
          magnam qui, dolor nesciunt enim reiciendis magni veniam tenetur
          provident alias quisquam error quod.Lorem ipsum dolor, sit amet
          consectetur adipisicing elit. Ullam, itaque minus! Blanditiis sequi
          repudiandae, praesentium dignissimos, magnam qui, dolor nesciunt enim
          reiciendis magni veniam tenetur provident alias quisquam error
          quod.Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam,
          itaque minus! Blanditiis sequi repudiandae, praesentium dignissimos,
          magnam qui, dolor nesciunt enim reiciendis magni veniam tenetur
          provident alias quisquam error quod.
        </span>
      </div>
      <div className="d-flex justify-content-center my-4">
        <ul className="w-75">
          <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
          <li>Ullam, itaque minus! Blanditiis sequi repudiandae</li>
          <li>Blanditiis sequi repudiandae, praesentium dignissimos</li>
          <li>Dolor nesciunt enim reiciendis magni veniam tenetur</li>
        </ul>
      </div>
      <div className="d-flex justify-content-center">
        <span className="w-75 mb-5">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam,
          itaque minus! Blanditiis sequi repudiandae, praesentium dignissimos,
          magnam qui, dolor nesciunt enim reiciendis magni veniam tenetur
          provident alias quisquam error quod.Lorem ipsum dolor, sit amet
          consectetur adipisicing elit. Ullam, itaque minus! Blanditiis sequi
          repudiandae, praesentium dignissimos, magnam qui, dolor nesciunt enim
          reiciendis magni veniam tenetur provident alias quisquam error
          quod.Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam,
          itaque minus! Blanditiis sequi repudiandae, praesentium dignissimos,
          magnam qui, dolor nesciunt enim reiciendis magni veniam tenetur
          provident alias quisquam error quod.
        </span>
      </div>
    </div>
  );
}

export default Main;
