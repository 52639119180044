import React from 'react';
import { RiCloseFill } from "react-icons/ri";

const SkillProgress = ({
 skill,
 skillsData,
 onRatingChange = () => { },
 onRemoveSkill = () => { },
 editButton,
 editable,
}) => {
 return (
  <div className="d-flex gap-2 flex-wrap align-items-center">
   <p className="fs-14 mb-0 flex-grow-1 text-break fw-bold" style={{ flexBasis: "calc(33% - 1.5rem)" }}>{skill.name}</p>
   {
    editable && <div className="d-flex align-items-center gap-1">
     {skillsData?.length >= 2 && <div className="cursor-pointer" onClick={onRemoveSkill}>
      <RiCloseFill />
     </div>}
    </div>
   }
   <div className="d-flex gap-2 justify-content-between flex-grow-1" style={{ flexBasis: "66%" }}>
    <div className="rating-selector">
     {[1, 2, 3, 4, 5].map((rating) => (
      <React.Fragment key={rating}>
       <button
        type="button"
        className={`rating-circle fs-12 ${skill.rating >= rating ? 'selected' : ''}`}
        onClick={() => onRatingChange(rating)}
        aria-label={`Rate ${rating}`}
       >
        {rating}
       </button>
       {rating < 5 && <span className="connector-line"></span>}
      </React.Fragment>
     ))}
    </div>
    {editButton}
   </div>
  </div>
 );
};

export default SkillProgress;