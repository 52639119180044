import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function RequireAuth() {
 const { token: userToken } = useSelector((state) => state.userData);
 const { token: designerToken } = useSelector((state) => state.designerData);
 const { token: adminToken } = useSelector((state) => state.adminData);

 const { pathname, state } = useLocation();
 const variant = pathname.match(/^\/([^/]+)/)[1];

 useEffect(() => {
  let timerId;
  if (state?.from === "token-check") {
   timerId = setTimeout(() => toast.info("You need to log out of the current session in order to access the requested page."));
  }
  return () => clearTimeout(timerId);
  // eslint-disable-next-line
 }, []);

 switch (variant) {
  case "user":
   return !userToken ? <Navigate to="/login" /> : <Outlet />;
  case "designer":
   return !designerToken ? <Navigate to="/login" /> : <Outlet />;
  case "admin":
   return !adminToken ? <Navigate to="/login" /> : <Outlet />;
  default:
   return <Navigate to="/" />;
 }
}

export default RequireAuth;
