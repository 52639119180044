import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { singleImageUpload, updateDesignerData } from "../../../api/Api";
import { setDesigner } from "../../../redux/actions/designerActions";
import { RiImageEditLine } from "react-icons/ri";

function ProfileCover({ data, setData, token }) {
 const [loading, setLoading] = useState();
 const [profileCover, setProfileCover] = useState(data.coverImg || "/assets/images/16x9-placeholder.jpg");

 useEffect(() => {
  setProfileCover(
   data.coverImg || "/assets/images/16x9-placeholder.jpg"
  );
 }, [data]);

 const fileInputRef = useRef(null);
 const dispatch = useDispatch();
 const [imageSrc, setImageSrc] = useState(profileCover);

 useEffect(() => {
  setImageSrc(profileCover);
 }, [profileCover]);

 const handleFileChange = async (event) => {
  const file = event.target.files[0];
  if (file) {
   setLoading(true);

   const formdata = new FormData();
   formdata.append("image", file);
   const result = await singleImageUpload(formdata);
   if (result.status === "success") {
    const imageUrl = result.data;
    updateProfile(imageUrl);
   } else {
    toast.error("Fail To Upload Image");
   }
   setLoading(false);
  }
 };

 const updateProfile = async (imageUrl) => {
  const result = await updateDesignerData(
   { coverImg: imageUrl },
   token
  );

  if (result.status === "success") {
   toast.success("Profile Cover Updated Successfully");
   dispatch(setDesigner(result.data));
   setImageSrc(result.data.coverImg);
   setData(oldData => ({
    ...oldData,
    coverImg: result.data.coverImg,
   }));
  } else {
   toast.error(result.message);
  }
 };

 return (
  <div className="profile-wid-bg profile-setting-img">
   <img src={imageSrc} className="profile-wid-img" alt="Cover" />
   <input
    ref={fileInputRef}
    type="file"
    name="coverImg"
    id="coverImg"
    className="profile-foreground-img-file-input"
    onChange={handleFileChange}
    disabled={loading}
   />
   <label
    htmlFor="coverImg"
    className="profile-photo-edit d-flex align-items-center gap-2 btn btn-light shadow-sm fs-14"
   >
    <RiImageEditLine /> <span>Change Cover</span>
   </label>
  </div>
 );
}

export default ProfileCover;
