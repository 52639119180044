import React, { useState } from "react";
import { toast } from "react-toastify";
import { contactForm } from "../../api/Api";

function Main() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleForm = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await contactForm({ name, email, subject, message });
      if (result.status === "success") {
        toast.success(result.message || "Form Submit Successful!");
        setEmail("");
        setName("");
        setSubject("");
        setMessage("");
      } else {
        toast.error(result.message || "Failed To Submit.");
      }
    } catch (error) {
      toast.error(
        "An error occurred during form submission. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="container">
      <div className="text-center mt-5 pt-5 display-5">Contact Us</div>
      <div className="custom-card  my-5">
        <div className="row py-5 py-md-0">
          <div className="col-md-6 d-none d-md-block">
            <img
              className="w-100 contact-img"
              src="assets/images/contact.png"
              alt=""
            />
          </div>
          <div className="col-md-6 col-12 my-auto">
            <form onSubmit={handleForm}>
              <div className="px-3">
                <h3>Get In Touch</h3>
                <div className="form-floating mt-3">
                  <input
                    type="name"
                    name="name"
                    value={name}
                    className="form-control"
                    placeholder="Name"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label htmlFor="floatingInput">Name</label>
                </div>
                <div className="form-floating mt-3">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    className="form-control"
                    placeholder="Email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="floating">Email</label>
                </div>
                <div className="form-floating mt-3">
                  <input
                    type="Subject"
                    name="subject"
                    value={subject}
                    className="form-control"
                    placeholder="Subject"
                    required
                    onChange={(e) => setSubject(e.target.value)}
                  />
                  <label htmlFor="floating">Subject</label>
                </div>
                <div className="form-floating mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    value={message}
                    placeholder="Type Message..."
                    style={{ height: "150px" }}
                    required
                    onChange={(e) => setMessage(e.target.value)}
                  />

                  <label htmlFor="floatingTextarea">Message</label>
                </div>
                <button
                  className="btn btn-dark w-100 rounded-5 py-2 mt-4"
                  disabled={loading}
                >
                  {loading ? "LOADING..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
