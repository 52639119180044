import { DesignerActionTypes } from "../constants/actionTypes";

export const setDesigner = (data) => {
 return {
   type: DesignerActionTypes.SET_DESIGNER,
   payload: data,
 };
};

export const setDesignerToken = (data) => {
 return {
   type: DesignerActionTypes.SET_TOKEN,
   payload: data,
 };
};

export const designerLogout = () => {
return {
  type: DesignerActionTypes.LOGOUT,
};
};
