import React, { useEffect, useState } from "react";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { Link } from "react-router-dom";
import { useSidebarContext } from "../../contexts/dashboardContext";
import { designerFeature, designerStatus, getAllDesigners } from "../../api/Api";
import moment from "moment";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

function DesignersManagement() {
  const { width } = useWindowSize();
  const { sidebarOpened } = useSidebarContext();

  const [designerRows, setDesignerRows] = useState([]);
  const [filteredDesigners, setFilteredDesigners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleStatus = async (id) => {
    setLoading(true);

    try {
      const result = await designerStatus({ id });
      if (result.status === "success") {
        toast.success(result.message || "Status updated successfully!");
        const res = await getAllDesigners();
        if (res.status === "success") {
          setDesignerRows(res.data);
          setFilteredDesigners(res.data);
        }
      } else {
        toast.error(result.message || "Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleFeature = async (id) => {
    setLoading(true);

    try {
      const result = await designerFeature({ designerId:id });
      if (result.status === "success") {
        toast.success(result.message || "Status updated successfully!");
        const res = await getAllDesigners();
        if (res.status === "success") {
          setDesignerRows(res.data);
          setFilteredDesigners(res.data);
        }
      } else {
        toast.error(result.message || "Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const fetchDesigners = async () => {
      setLoading(true);
      const res = await getAllDesigners();

      if (res.status === "success") {
        setDesignerRows(res.data);
        setFilteredDesigners(res.data);
      }
      setLoading(false);
    };

    fetchDesigners();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const result = designerRows.filter((designer) => {
      return (
        (designer.name &&
          designer.name.toLowerCase().includes(search.toLowerCase())) ||
        (designer.username &&
          designer.username.toLowerCase().includes(search.toLowerCase())) ||
        (designer.email &&
          designer.email.toLowerCase().includes(search.toLowerCase()))
      );
    });
    setFilteredDesigners(result);
  }, [search, designerRows]);

  useEffect(() => {
    const dashboardLayout = document.querySelector(".dashboard-layout");
    const card = document.querySelector(".designers-management-card");

    if (width < 768) {
      card.style.width = dashboardLayout.clientWidth - 39 + "px";
    } else if (!sidebarOpened) {
      card.style.width = dashboardLayout.clientWidth - 109 + "px";
    } else {
      card.style.width = dashboardLayout.clientWidth - 289 + "px";
    }
  }, [width, sidebarOpened]);

  const columns = [
    {
      name: "Sr #",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Designer",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Joining Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <span
          className={`badge ${
            row.isActive ? "bg-success-alt" : "bg-danger-alt"
          } text-white`}
        >
          {row.isActive ? "Active" : "Inactive"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex gap-1">
          <Link
            to={{
              pathname: "/admin/designer-details",
            }}
            state={{ designer: row }}
          >
            <button
              style={{ fontSize: 12.25 }}
              className="d-flex align-items-center gap-1 btn btn-lg bg-secondary text-white"
            >
              View
            </button>
          </Link>
          <button
            style={{ fontSize: 12.25 }}
            onClick={() => handleStatus(row._id)}
            className={`d-flex align-items-center gap-1 btn btn-lg ${
              row.isActive ? "btn-danger" : "btn-success"
            }`}
          >
            {row.isActive ? "Deactivate" : "Active"}
          </button>
          <button
            style={{ fontSize: 12.25 }}
            onClick={() => handleFeature(row._id)}
            className={`d-flex align-items-center gap-1 btn btn-lg ${
              row.isActive ? "btn-warning" : "btn-success"
            }`}
          >
            {row.featured ? "Unfeatured" : "Featured"}
          </button>
        </div>
      ),
      width: "fit-content",
    },
  ];

  return (
    <section className="dashboard-section admin-designers-management">
      <div
        className="d-flex gap-2 flex-wrap justify-content-between align-items-center"
        style={{ padding: "19px 24px", marginBottom: 5 }}
      >
        <h4
          className="text-nowrap"
          style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
        >
          DESIGNERS MANAGEMENT
        </h4>
        <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
          <li className="fs-14 breadcrumb-item">
            <Link to="/admin/dashboard">Dashboard</Link>
          </li>
          <li className="fs-14 breadcrumb-item active">Designers</li>
        </ol>
      </div>
      <div className="section-spacing">
        <div className="card designers-management-card">
          <div className="card-header d-flex flex-wrap gap-2 align-items-center justify-content-between">
            <h5
              className="card-title mb-0 flex-grow-1"
              style={{ minWidth: "fit-content" }}
            >
              Designers
            </h5>
            <input
              type="text"
              className="form-control"
              placeholder="Search by Name, Username or Email"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ maxWidth: "250px", height: "28px" }}
            />
          </div>
          <div className="card-body fs-14">
            <DataTable
              columns={columns}
              data={filteredDesigners}
              progressPending={loading}
              pagination
              highlightOnHover
              striped
              responsive
              persistTableHead
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default DesignersManagement;
