import React from "react";

function Breadcrumb() {
  return (
    <div className="text-center mt-5">
      <span className="about-badge">About Us</span>
      <div className="display-5 mt-3">
        The world’s destination <br /> for design
      </div>
    </div>
  );
}

export default Breadcrumb;
