import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useSidebarContext } from "../../contexts/dashboardContext";
import { getAllClients, userStatus } from "../../api/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

function ClientManagement() {
  const { width } = useWindowSize();
  const { sidebarOpened } = useSidebarContext();
  const [clientRows, setClientRows] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const adminToken = useSelector((state) => state.adminData.token);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      const res = await getAllClients(adminToken);

      if (res.status === "success") {
        setClientRows(res.data);
        setFilteredClients(res.data); // Initialize filteredClients with the full list
      } else {
        toast.error("Failed to fetch clients.");
      }
      setLoading(false);
    };

    fetchClients();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const result = clientRows.filter((client) => {
      return (
        client.name.toLowerCase().includes(search.toLowerCase()) ||
        client.email.toLowerCase().includes(search.toLowerCase())
      );
    });
    setFilteredClients(result);
  }, [search, clientRows]);

  const handleStatus = async (id) => {
    setLoading(true);

    try {
      const result = await userStatus({ id });
      if (result.status === "success") {
        toast.success(result.message || "Status updated successfully!");
        const res = await getAllClients(adminToken);
        if (res.status === "success") {
          setClientRows(res.data);
        }
      } else {
        toast.error(result.message || "Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "Sr #",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "User",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Joining Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <span
          className={`badge ${
            row.isActive ? "bg-success-alt" : "bg-danger-alt"
          } text-white`}
        >
          {row.isActive ? "Actived" : "Deactived"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex gap-1">
          <Link
            to="/admin/client-details"
            state={{ client: row }} // Correctly pass the state here
          >
            <button
              style={{ fontSize: 12.25 }}
              className="d-flex align-items-center gap-1 btn btn-lg bg-secondary text-white"
            >
              View
            </button>
          </Link>
          <button
            style={{ fontSize: 12.25 }}
            onClick={() => handleStatus(row._id)}
            className={`d-flex align-items-center gap-1 btn btn-lg ${
              row.isActive ? "btn-danger" : "btn-success"
            }`}
          >
            {row.isActive ? "Deactivate" : "Activate"}
          </button>
        </div>
      ),
      width: "fit-content",
    },
  ];

  useEffect(() => {
    const dashboardLayout = document.querySelector(".dashboard-layout");
    const card = document.querySelector(".client-management-card");

    if (width < 768) {
      card.style.width = dashboardLayout.clientWidth - 39 + "px";
    } else if (!sidebarOpened) {
      card.style.width = dashboardLayout.clientWidth - 109 + "px";
    } else {
      card.style.width = dashboardLayout.clientWidth - 289 + "px";
    }
  }, [width, sidebarOpened]);

  return (
    <>
      <section className="dashboard-section admin-client-management">
        <div
          className="d-flex gap-2 flex-wrap justify-content-between"
          style={{ padding: "19px 24px", marginBottom: 5 }}
        >
          <h4
            className="text-nowrap"
            style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
          >
            CLIENT MANAGEMENT
          </h4>
          <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
            <li className="fs-14 breadcrumb-item">
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li className="fs-14 breadcrumb-item active">Client Management</li>
          </ol>
        </div>
        <div className="section-spacing">
          <div className="card client-management-card">
            <div className="card-header d-flex flex-wrap gap-2 align-items-center justify-content-between">
              <h5
                className="card-title mb-0 flex-grow-1"
                style={{ minWidth: "fit-content" }}
              >
                Client Users
              </h5>
              <input
                type="text"
                className="form-control"
                placeholder="Search by Name or Email"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ maxWidth: "250px", height: "28px" }}
              />
            </div>
            <div className="card-body fs-14">
              <DataTable
                columns={columns}
                data={filteredClients}
                progressPending={loading}
                pagination
                highlightOnHover
                striped
                responsive
                persistTableHead
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ClientManagement;
