import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

function TokenCheck() {
 const { token: userToken } = useSelector((state) => state.userData);
 const { token: designerToken } = useSelector((state) => state.designerData);
 const { isTokenInitialized } = useSelector((state) => state.generalState);

 if (userToken !== "") {
  if (isTokenInitialized) {
   return <Navigate to="/user/dashboard" />;
  } else {
   return <Navigate to="/user/dashboard" state={{ from: "token-check" }} />;
  }
 } else if (designerToken !== "") {
  if (isTokenInitialized) {
   return <Navigate to="/designer/dashboard" />;
  } else {
   return <Navigate to="/designer/dashboard" state={{ from: "token-check" }} />;
  }
 } else return <Outlet />;
}

export default TokenCheck;
