import {
  FaArchive,
  FaTags,
  FaThLarge,
  FaThumbtack,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import { IoMdCodeWorking } from "react-icons/io";
import { MdDesignServices ,MdOutlineContactSupport } from "react-icons/md";
import {
  RiDashboard2Line,
  RiLockFill,
  RiLockLine,
  RiSlideshowLine,
  RiUser2Line,
} from "react-icons/ri";

export function getDashboardSidebarItems(variant, sidebarOpened) {
  switch (variant) {
    case "user":
      return [
        {
          text: "Dashboard",
          link: "/user/dashboard",
          icon: (
            <RiDashboard2Line
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={18}
            />
          ),
        },
        {
          text: "Profile Settings",
          link: "/user/profile",
          icon: (
            <RiUser2Line
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={18}
            />
          ),
        },
        {
          text: "Saved Projects",
          link: "/user/saved-portfolio",
          icon: (
            <RiSlideshowLine
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={18}
            />
          ),
        },
        {
          text: "Security Settings",
          link: "/user/security",
          icon: (
            <RiLockLine
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={18}
            />
          ),
        },
      ];
    case "designer":
      return [
        {
          text: "Dashboard",
          link: "/designer/dashboard",
          icon: (
            <RiDashboard2Line
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={18}
            />
          ),
        },
        {
          text: "Profile Settings",
          link: "/designer/profile",
          icon: (
            <RiUser2Line
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={18}
            />
          ),
        },
        {
          text: "Manage Portfolio",
          link: "/designer/portfolio",
          icon: (
            <RiSlideshowLine
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={18}
            />
          ),
        },
        {
          text: "Security Settings",
          link: "/designer/security",
          icon: (
            <RiLockLine
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={18}
            />
          ),
        },
      ];
    case "admin":
      return [
        {
          text: "Dashboard",
          link: "/admin/dashboard",
          icon: (
            <FaThLarge
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={16}
            />
          ),
        },
        {
          text: "Client Management",
          link: "/admin/users",
          icon: (
            <FaUsers
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={16}
            />
          ),
        },
        {
          text: "Designer Categories",
          link: "/admin/categories",
          icon: (
            <FaTags
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={16}
            />
          ),
        },
        {
          text: "Designer Skills",
          link: "/admin/skills",
          icon: (
            <IoMdCodeWorking
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={16}
            />
          ),
        },
        {
          text: "Designer Services",
          link: "/admin/services",
          icon: (
            <MdDesignServices
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={16}
            />
          ),
        },
        {
          text: "Designers Management",
          link: "/admin/designers",
          icon: (
            <FaUser
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={16}
            />
          ),
        },
        {
          text: "Portfolio Management",
          link: "/admin/portfolio",
          icon: (
            <FaArchive
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={16}
            />
          ),
        },
        {
          text: "Featured Portfolio",
          link: "/admin/featured-portfolio",
          icon: (
            <FaThumbtack
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={16}
            />
          ),
        },
        {
          text: "Queries",
          link: "/admin/queries",
          icon: (
            <MdOutlineContactSupport
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={16}
            />
          ),
        },
        {
          text: "Security Settings",
          link: "/admin/security",
          icon: (
            <RiLockFill
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={16}
            />
          ),
        },
      ];
    default:
      return [
        {
          text: "Dashboard",
          link: "/user/dashboard",
          icon: (
            <RiDashboard2Line
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={18}
            />
          ),
        },
        {
          text: "Profile Settings",
          link: "/user/profile",
          icon: (
            <RiUser2Line
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={18}
            />
          ),
        },
        {
          text: "Saved Projects",
          link: "/user/saved-portfolio",
          icon: (
            <RiSlideshowLine
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={18}
            />
          ),
        },
        {
          text: "Security Settings",
          link: "/user/security",
          icon: (
            <RiLockLine
              color="#6d7080"
              style={{ transform: !sidebarOpened && "scale(1.2)" }}
              size={18}
            />
          ),
        },
      ];
  }
}
