import { toast } from "react-toastify";
// eslint-disable-next-line
import { get, post, put, del } from "./apiRequests";

// Single Image Upload
export async function singleImageUpload(formData, userToken) {
  try {
    const response = await post(
      "upload/single",
      formData,
      userToken,
      "formData"
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Signup User
export async function signUp(credentials) {
  try {
    const response = await post("auth/signup", {
      name: credentials.name,
      email: credentials.email,
      password: credentials.password,
      role: credentials.role,
    });
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// User Login with Email
export async function loginWithEmail(userData) {
  try {
    const response = await post("user/auth/login", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}
// designer Login with Email
export async function login(userData) {
  try {
    const response = await post("auth/login", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// designer Forget Password with Email
export async function designerforgotPassword(email) {
  try {
    const response = await post("auth/forgotPassword", { email });
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get User Data
export async function getUserData(userToken) {
  try {
    const response = await get("admin/user/getUserDetails", userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get Designer User Data
export async function getDesignerData(userToken) {
  try {
    const response = await get("admin/designer/getDesignerDetails", userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Update Designer Designer Data
export async function updateDesignerData(designerData, designerToken) {
  try {
    const response = await put(
      "designer/updateDesignerDetails",
      designerData,
      designerToken,
      "json"
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}
// Update User Data
export async function updateUserData(userData, userToken) {
  try {
    const response = await put(
      "user/updateUserDetails",
      userData,
      userToken,
      "json"
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Admin Login with Email
export async function adminLoginWithEmail(userData) {
  try {
    const response = await post("admin/login", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Change Admin Password With Token
export async function adminChangePassword(
  oldPassword,
  newPassword,
  adminToken
) {
  try {
    const response = await put(
      "admin/changePassword",
      { oldPassword, newPassword },
      adminToken
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All Clients
export async function getAllClients(adminToken) {
  try {
    const response = await get("admin/user/getAllUserDetails", adminToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All Clients
export async function getAllDesigners(adminToken) {
  try {
    const response = await get(
      "admin/designer/getAllDesignerDetails",
      adminToken
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// User Email Verify
export async function sendEmailVerificationLink(email, role) {
  try {
    const response = await post(
      "auth/sendVerificationLink",
      { email, role },
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// designer Email Verify
export async function updateEmailVerifiedStatus(token) {
  try {
    const response = await post(
      "auth/updateEmailVerifiedStatus",
      "",
      token
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

//Contact form
export async function contactForm(credentials) {
  try {
    const response = await post("contactus", {
      name: credentials.name,
      email: credentials.email,
      subject: credentials.subject,
      message: credentials.message,
    });
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All Admin Dashboard Data
export async function getAllData(adminToken) {
  try {
    const response = await get("admin/counts", adminToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// admin add Category
export async function addCategorie(name) {
  try {
    const response = await post("admin/category/add", { name });
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All Category
export async function getAllCategory() {
  try {
    const response = await get("admin/category/getall", "");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}
// Admin Category status
export async function categoryStatus(userData) {
  try {
    const response = await post("admin/category/changestatus", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Admin Edit Category
export async function editCategory(userData) {
  try {
    const response = await put("admin/update/category", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All portfolio
export async function getAllPortfolio() {
  try {
    const response = await get("admin/portfolio/getAllPortfolios", "");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}
// Admin portfolio status
export async function portfolioFeaturedStatus(userData) {
  try {
    const response = await post(
      "admin/portfolio/changeFeaturedStatus",
      userData
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Admin portfolio manag status
export async function portfolioStatus(userData) {
  try {
    const response = await put("admin/portfolio/changeStatus", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// admin designer status
export async function designerStatus(userData) {
  try {
    const response = await put("admin/designer/changeStatus", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// admin designer feature
export async function designerFeature(userData) {
  try {
    const response = await post("admin/designer/changeFeaturedStatusDesigner", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// getPortfoliosByDesigner

export async function getPortfoliosByDesigner(designerToken) {
  try {
    const response = await get(
      "admin/portfolio/getAllDesignerPortfolios",
      designerToken
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// getDesignerPortfolioById

export async function getDesignerPortfolioById(id, designerToken) {
  try {
    const response = await get(
      `portfolio/getDesignerPortfolioById/${id}`,
      designerToken
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// deletePortfolio

export async function deletePortfolio(id, designerToken) {
  try {
    const response = await del(
      `portfolio/deletePortfolio`,
      { portfolioId: id },
      designerToken
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// createPortfolio

export async function createPortfolio(data, designerToken) {
  try {
    const response = await post(
      `portfolio/createPortfolio`,
      data,
      designerToken,
      "json",
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// updatePortfolio

export async function updatePortfolio(data, designerToken) {
  try {
    const response = await put(
      `portfolio/updatePortfolio`,
      data,
      designerToken,
      "json",
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// user designer status
export async function userStatus(userData) {
  try {
    const response = await put("admin/user/changeStatus", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All Featured portfolio
export async function getAllFeaturedPortfolio() {
  try {
    const response = await get("admin/portfolio/getAllFeaturedPortfolios", "");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get Latest Featured portfolio
export async function getlatestFeaturedPortfolio() {
  try {
    const response = await get("user/portfolio/latestfeatured", "");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get Latest Designers
export async function getlatestDesigner() {
  try {
    const response = await get("designer/latestdesigners", "");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Admin skills  status
export async function skillsStatus(userData) {
  try {
    const response = await post("admin/skill/changestatus", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Admin Edit skills
export async function editSkills(userData) {
  try {
    const response = await put("admin/update/skill", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// admin add skills
export async function addskills(name) {
  try {
    const response = await post("admin/skill/add", { name });
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Admin services status
export async function servicesStatus(userData) {
  try {
    const response = await post("admin/services/changestatus", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// admin add services
export async function addServices(name) {
  try {
    const response = await post("admin/services/add", { name });
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Admin Edit skills
export async function editServices(userData) {
  try {
    const response = await put("admin/update/services", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get  Designers By Id
export async function getPortfoliobyId(id) {
  try {
    const response = await get(`portfolio/getPortfolioById/${id}`, "");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All Categories

export async function getAllCategories() {
  try {
    const response = await get("admin/category/getall", "");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All Services

export async function getAllServices() {
  try {
    const response = await get("admin/services/getall", "");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All Skills

export async function getAllSkills() {
  try {
    const response = await get("admin/skill/getall", "");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get  Designers By Id with portfolio
export async function getDesignerbyId(id) {
  try {
    const response = await get(`designer/getDesignersPortfolio/${id}`, "");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// User Save Project
export async function saveProject(userData, userToken) {
  try {
    const response = await post("savedproject/save", userData, userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Change User Password With Token
export async function userChangePassword(oldPassword, newPassword, userToken) {
  try {
    const response = await post(
      "user/auth/changePassword",
      { oldPassword, newPassword },
      userToken
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// User portfolio Save
export async function getSaveProject(userToken) {
  try {
    const response = await get("getall/savedprojects", userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// User portfolio Unsave
export async function unSavePortfolio(id, userToken) {
  try {
    const response = await del(`savedproject/unsave/${id}`, "", userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Change designer Password With Token
export async function designerChangePassword(
  oldPassword,
  newPassword,
  userToken
) {
  try {
    const response = await post(
      "designer/auth/changePassword",
      { oldPassword, newPassword },
      userToken
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

//Contact designer form
export async function contactDesignerForm(credentials) {
  try {
    const response = await post("designer/contactnow", {
      name: credentials.name,
      lastname: credentials.lastname,
      email: credentials.email,
      phone: credentials.phone,
      message: credentials.message,
      designerId: credentials.designerId,
    });
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// getDesignersBySearchTerm

export async function getDesignersBySearchTerm(params, userToken, controller) {
 try {
   const response = await get(`designer/search/?searchTerm=${encodeURIComponent(params.searchTerm)}&searchCategory=${encodeURIComponent(params.searchCategory)}&page=${encodeURIComponent(params.page)}`, userToken, undefined, controller);
   return response;
 } catch (error) {
   toast.error(error.message || "Something went wrong", "error");
 }
}

// User Comment
export async function commentPost(userData, userToken) {
  try {
    const response = await post("comment/add", userData, userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All Comment

export async function getAllComments(id) {
  try {
    const response = await get(`portfolio/${id}`, "");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}


// deleteComment

export async function deleteComment(id, userToken) {
  try {
    const response = await del(
      `delete/${id}`,"",userToken
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All Contactus
export async function getAllContacts() {
  try {
    const response = await get("admin/contactus/getall", "");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Designer Dashboard Data

export async function getDesignerDashboard(id) {
  try {
    const response = await get(`designer/portfoliosstats/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// User Dashboard Data

export async function getUserDashboard(id) {
  try {
    const response = await get(`user/savedprojectscount/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}