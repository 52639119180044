import React, { useEffect, useState } from "react";
import { sendEmailVerificationLink, getDesignerData, getDesignerDashboard } from "../../api/Api";
import { useSelector } from "react-redux";
import { DangerSignIcon } from "../../utils/icons";
import { toast } from "react-toastify";

function DesignerDashboard() {
  const [designerData, setDesignerData] = useState();
  const [isSendingEmail, setIsSendingEmail] = useState();
  const { token: designerToken } = useSelector((state) => state.designerData);
  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(true);
  const { designer } = useSelector(state => state.designerData);
  useEffect(() => {
    const fetchDesignerData = async () => {
      const result = await getDesignerData(designerToken);

      if (result.status === "success") {
        setDesignerData(result.data);
      }
    };

    fetchDesignerData();
    // eslint-disable-next-line
  }, []);

  const handleSendVerificationLink = async () => {
    setIsSendingEmail(true);
    const result = await sendEmailVerificationLink(designer.email, "designer");

    if (result.status === "success") {
      toast.success("Email Verification Link Sent");
    } else {
      toast.error("Error Sending Verification Link");
    }
    setIsSendingEmail(false);
  };
  useEffect(() => {
    const fetchDashboardData = async () => {
      const res = await getDesignerDashboard(designer._id);
      if (res.status === "success") {
        setDashboardData(res.data);
      }
      setLoading(false);
    };

    fetchDashboardData();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="dashboard-section designer-dashboard">
      <div style={{ padding: "19px 24px", marginBottom: 5 }}>
        <h4 style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}>
          DASHBOARD
        </h4>
      </div>
      <div className="section-spacing">
        {(designer?.emailVerified || designerData?.emailVerified) ? (
          <div className="row">
            {loading ? (
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
            <div className="col-xxl-5">
              <div className="d-flex flex-column h-100">
                <div className="row fs-14">
                  <div className="col-md-6">
                    <div className="card card-animate">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div>
                            <p className="fw-medium text-muted mb-0">Total Portfolio</p>
                            <h4 className="mt-4 fs-22 ff-secondary fw-semibold">
                              <span className="counter-value" data-target="1">
                                {dashboardData?.totalPortfolios}
                              </span>
                            </h4>
                          </div>
                          <div>
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-bookmark text-info"
                                >
                                  <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card card-animate">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div>
                            <p className="fw-medium text-muted mb-0">Views</p>
                            <h4 className="mt-4 fs-22 ff-secondary fw-semibold">
                              <span className="counter-value" data-target="118">
                              {dashboardData?.totalViews}

                              </span>
                            </h4>
                          </div>
                          <div>
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-activity text-info"
                                >
                                  <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )}
          </div>
        ) : (
          <div className="card">
            <div className="card-body p-0">
              <div
                className="alert alert-warning border-0 rounded-0 m-0 d-flex align-items-center"
                role="alert"
              >
                <DangerSignIcon
                  color="rgb(239, 174, 78)"
                  className="flex-shrink-0"
                />
                <div className="flex-grow-1 text-truncate" style={{ width: 0 }}>
                  <span className="fw-semibold fs-14">
                    Welcome! To unlock the full potential of this platform,
                    please verify your email address. We've sent a verification
                    link to your inbox.
                  </span>
                </div>
              </div>
              <p className="fs-16 p-3">
                Open your inbox to find the email verification link or{" "}
                <button
                  className="btn btn-link text-decoration-none p-0 text-warning fw-semibold"
                  onClick={handleSendVerificationLink}
                  disabled={isSendingEmail}
                >
                  Click Here
                </button>{" "}
                to generate a new verification link.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DesignerDashboard;
