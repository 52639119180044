import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getPortfoliobyId, saveProject } from "../../../api/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DesignerProfileCard from "./Project/DesignerProfileCard";
import ProjectCard from "./Project/ProjectCard";
import OtherProjects from "./Project/OtherProjects";

function DesignerDetail() {
  const { id } = useParams();
  const [designer, setDesigner] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDesigner = async () => {
      setLoading(true);
      const res = await getPortfoliobyId(id);

      if (res.status === "success") {
        setDesigner(res.data);
      }
      setLoading(false);
    };

    fetchDesigner();
  }, [id]);

  const { token: userToken } = useSelector((state) => state.userData);
  const handleSave = async () => {
    setLoading(true);
    try {
      const result = await saveProject({ portfolioId: id }, userToken);
      if (result.status === "success") {
        toast.success(result.message || "Saved Successfully!");
      } else {
        toast.error(result.message || "Failed to Save.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  if (loading) {
    return <div className="text-center my-5">Loading...</div>;
  }
  return (
    <div className="container my-5 mt-5 pt-5">
      <div className="row my-4">
        <ProjectCard designer={designer?.currentPortfolio} />
        <div className="col-md-4 mt-md-5 mt-4 pt-md-3 order-md-1 order-0">
          <div className="designer rounded-3 border-0 bg-white shadow d-flex justify-content-center align-items-center p-3 mb-4">
            {userToken ? (
              <button
                type="submit"
                className="btn rounded py-1 custom-btn text-white"
                onClick={handleSave}
              >
                <small>Add to Collection</small>
              </button>
            ) : (
              <Link
                to="/user/login"
                className="btn rounded py-1 custom-btn text-white"
              >
                <small>Login as User to Save Project</small>
              </Link>
            )}
          </div>
          <DesignerProfileCard
            designer={designer?.currentPortfolio?.designerId}
          />
          <OtherProjects designer={designer}/>
        </div>
      </div>
    </div>
  );
}

export default DesignerDetail;
