import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageGalleryWithSlider = ({ images }) => {
 const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
   {...props}
   className={
    "slick-prev slick-arrow" +
    (currentSlide === 0 ? " slick-disabled" : "") +
    props.className
   }
   aria-hidden="true"
   aria-disabled={currentSlide === 0}
   type="button"
  >
   <FaChevronLeft />
  </button>
 );

 const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
   {...props}
   className={
    "slick-next slick-arrow" +
    (currentSlide === slideCount - 1 ? " slick-disabled" : "") +
    props.className
   }
   aria-hidden="true"
   aria-disabled={currentSlide === slideCount - 1}
   type="button"
  >
   <FaChevronRight />
  </button>
 );

 const settingsMain = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  fade: true,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,
 };

 if (images?.length === 0) {
  return <div>No images to display</div>;
 }

 return (
  <div
   className="image-gallery h-100"
   style={{ width: "100%", maxWidth: "800px", margin: "0 auto" }}
  >
   <Slider
    {...settingsMain}
    className="main-slider"
    style={{ height: "100%" }}
   >
    {images?.map((img, idx) => (
     <div key={idx} className="main-slide h-100">
      <img
       src={img}
       alt={`Main ${idx + 1}`}
       style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
     </div>
    ))}
   </Slider>
  </div>
 );
};

export default ImageGalleryWithSlider;
