import React, { useEffect, useState } from "react";
import { FaFacebook, FaTwitter, FaRegCopy } from "react-icons/fa";
import { IoShareOutline } from "react-icons/io5";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import { FaLocationDot, FaRegCirclePlay } from "react-icons/fa6";
import { useLocation, useParams } from "react-router-dom";
import Card from "../Card";
import Categories from "./Categories";

import About from "./About";
import ContactNow from "./ContactNow";
import { getDesignerbyId } from "../../../../api/Api";
import VideoModal from "./VideoModal";

function DesignerProfile() {
  const { state } = useLocation();

  const [activeTab, setActiveTab] = useState(
    state?.activeTab || (state?.fromHomepage ? "projects" : "about")
  );
  const [designer, setDesigner] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchDesigner = async () => {
      setLoading(true);
      const res = await getDesignerbyId(id);
      if (res?.status === "success") {
        setDesigner(res.data);
      }
      setLoading(false);
    };

    fetchDesigner();
  }, [id]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center my-5 vh-100 align-items-center">
        <h4>Loading...</h4>
      </div>
    );
  }

  const designerProfileUrl = `https://www.iamadesigner.com/designer-profile/${id}`;

  const shareOnFacebook = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    designerProfileUrl
  )}`;
  const shareOnTwitter = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    designerProfileUrl
  )}&text=Check%20out%20this%20designer!`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(designerProfileUrl);
    alert("Link copied to clipboard!");
  };

  const handleDownloadOrPrint = () => {
    window.print(); // This will trigger the browser's print dialog
  };

  const renderContent = () => {
    switch (activeTab) {
      case "about":
        return (
          <div className="container">
            <div className="row">
              <About designer={designer.designer} />
              <Categories designer={designer.designer} />
            </div>
          </div>
        );
      case "projects":
        return (
          <div>
            <div className="row mb-4">
              {designer?.portfolios && designer.portfolios.length > 0 ? (
                designer.portfolios.map((portfolio, index) => (
                  <div className="col-md-3 mb-4" key={index}>
                    <Card
                      portfolio={portfolio}
                      key={portfolio._id}
                      id={portfolio._id}
                      img={portfolio.img || "/assets/images/header-avatar.png"}
                      userName={designer?.designer?.name}
                      userimg={designer?.designer?.profileImg}
                      hovertext={portfolio.title}
                      subTitle={portfolio.subtitle}
                      casee="profile"
                      designer={designer?.designer}
                      setActiveTab={setActiveTab}
                    />
                  </div>
                ))
              ) : (
                <p className="text-center">No Portfolio created</p>
              )}
            </div>
          </div>
        );
      case "resume":
        return (
          <div className="container my-5">
            <div className="row justify-content-center">
              <div className="col-md-8 offset-md-2 col-12 mx-auto resume">
                {designer?.designer?.resume ? (
                  <>
                    <div className="text-end">
                    <button
                      className="btn btn-primary mb-3"
                      onClick={handleDownloadOrPrint}
                    >
                      Download/Print
                    </button>
                    </div>
                    <div className="print-resume">
                      <img
                        src={designer?.designer?.resume}
                        width="100%"
                        alt="resume"
                      />
                    </div>
                  </>
                ) : (
                  <p className="text-center">No resume attached</p>
                )}
              </div>
            </div>
          </div>
        );
      case "contact":
        return <ContactNow designer={designer.designer} />;
      default:
        return <div>About Content</div>;
    }
  };

  return (
    <div className="container mt-5 pt-3">
      <div className="designer-cover">
        <img
          src={
            designer?.designer?.coverImg ||
            "/assets/images/16x9-placeholder.jpg"
          }
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
          alt="Cover"
        />
      </div>
      <div className="container">
        <div>
          <div className="profile position-relative">
            <img
              src={
                designer?.designer?.profileImg ||
                "/assets/images/header-avatar.png"
              }
              alt="Profile"
            />
            {designer?.designer?.introVideo && (
              <div
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                className="play-btn"
              >
                <FaRegCirclePlay size={40} color="white" />
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9">
            <div className="m-3 profile-text">
              <h1 className="m-0">
                {designer?.designer?.name}{" "}
                <span style={{ color: "#9C8A4A" }}>
                  <Popup
                    trigger={
                      <span className="fs-4" style={{ cursor: "pointer" }}>
                        <IoShareOutline />
                      </span>
                    }
                    position="bottom center"
                    on="click"
                    closeOnDocumentClick
                  >
                    <div className="d-flex justify-content-around">
                      <a
                        href={shareOnFacebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nav-color"
                      >
                        <FaFacebook />
                      </a>

                      <a
                        href={shareOnTwitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nav-color"
                      >
                        <FaTwitter />
                      </a>

                      <span
                        onClick={copyToClipboard}
                        className="nav-color"
                        style={{ cursor: "pointer" }}
                      >
                        <FaRegCopy />
                      </span>
                    </div>
                  </Popup>
                </span>
              </h1>
              <p className="text-black my-2" style={{ fontSize: "18px" }}>
                I am a {designer?.designer?.defineYourself}
              </p>
              <div className="d-flex">
                {(designer?.designer?.city || designer?.designer?.country) && (
                  <span
                    className="text-muted m-0 d-flex align-items-center"
                    style={{ fontSize: "16px" }}
                  >
                    <FaLocationDot style={{ marginRight: "8px" }} />
                    {designer?.designer?.city && designer?.designer?.country
                      ? `${designer.designer.city}, ${designer.designer.country}`
                      : designer?.designer?.city || designer?.designer?.country}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <div className="my-4">
              <ul className="d-flex justify-content-center gap-5 me-md-0 me-4 nav-color">
                <li
                  className={activeTab === "about" ? "active" : ""}
                  onClick={() => setActiveTab("about")}
                  style={{ cursor: "pointer" }}
                >
                  About
                </li>
                <li
                  className={activeTab === "projects" ? "active" : ""}
                  onClick={() => setActiveTab("projects")}
                  style={{ cursor: "pointer" }}
                >
                  Portfolio
                </li>
                <li
                  className={activeTab === "resume" ? "active" : ""}
                  onClick={() => setActiveTab("resume")}
                  style={{ cursor: "pointer" }}
                >
                  Resume
                </li>
                <li
                  className={activeTab === "contact" ? "active" : ""}
                  onClick={() => setActiveTab("contact")}
                  style={{ cursor: "pointer" }}
                >
                  Contact
                </li>
              </ul>
            </div>
            <div>{renderContent()}</div>
          </div>
        </div>
      </div>
      <VideoModal designer={designer?.designer} />
    </div>
  );
}

export default DesignerProfile;
