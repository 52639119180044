import React from "react";
import Navbar from "../components/common/landing/Navbar";
import Footer from "../components/common/landing/Footer";
import Main from "../components/SearchTalent/Main";

function SearchTalent() {
  return (
    <div>
      <Navbar />
      <Main />
      <Footer />
    </div>
  );
}

export default SearchTalent;
