import React, { useState } from "react";
import { Link } from "react-router-dom";
import { adminChangePassword } from "../../api/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function AdminSecuritySettings() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const adminToken = useSelector((state) => state.adminData.token);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("New Password and Confirm Password do not match.");
      return;
    }

    const res = await adminChangePassword(oldPassword, newPassword, adminToken);

    if (res.status === "success") {
      toast.success("Password Updated Successfully!");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } else {
      toast.error(res.errors?.[0].msg ?? res.message);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  return (
    <div className="dashboard-section admin-security-settings">
      <div
        className="d-flex gap-2 flex-wrap justify-content-between"
        style={{ padding: "19px 24px", marginBottom: 5 }}
      >
        <h4
          className="text-nowrap"
          style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
        >
          SECURITY SETTINGS
        </h4>
        <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
          <li className="fs-14 breadcrumb-item">
            <Link to="/admin/dashboard">Dashboard</Link>
          </li>
          <li className="fs-14 breadcrumb-item active">Security</li>
        </ol>
      </div>
      <div className="section-spacing">
        <form onSubmit={handleSubmit}>
          <input
            type="hidden"
            name="_token"
            value="2VH3TF6daqcGFcsv9H2T7mRWuXQdkaoLusV2KTI0"
            autoComplete="off"
          />
          <div className="col-xxl-12">
            <div className="card mt-xxl-n5">
              <div className="card-header">
                <h5 className="card-title mb-0">Change Password</h5>
              </div>
              <div className="card-body p-4">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label htmlFor="oldpassword" className="form-label">
                        Old Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="old_password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label htmlFor="newpassword" className="form-label">
                        New Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label htmlFor="confirmpassword" className="form-label">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="password_confirmation"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-end">
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AdminSecuritySettings;
