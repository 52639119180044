import React from "react";

function About({ designer }) {
  return (
    <div className="col-md-8">
      <h5 className="fw-semibold">About The Designer</h5>
      <p>{designer?.aboutMe}</p>
      {designer?.images && designer.images.length >= 3
        ? designer.images.map((image, index) => {
            if (index % 3 === 0) {
              return (
                <div className="row my-4" key={index}>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <img
                        src={designer.images[index] }
                        width={"100%"}
                        alt="Card"
                      />
                    </div>
                    {designer.images[index + 1] && (
                      <div className="mb-4 mb-md-0">
                        <img
                          src={designer.images[index + 1]}
                          width={"100%"}
                          alt="Explore"
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    {designer.images[index + 2] && (
                      <div>
                        <img
                          src={designer.images[index + 2]}
                          width={"100%"}
                          alt="Explore"
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            }
            return null; // Skip if index is not the start of a new row
          })
        : ""}
      {designer?.videos &&
        designer?.videos?.map((video, index) => (
          <div key={index}>
            <video src={video} width="100%" height={450} controls muted />
          </div>
        ))}
    </div>
  );
}

export default About;
