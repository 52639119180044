import { DesignerActionTypes } from "../constants/actionTypes";

const initialState = {
  designer: {},
  token: "",
};

export const designerReducer = (state = initialState, action) => {
  switch (action.type) {
    case DesignerActionTypes.SET_DESIGNER:
      return {
        ...state,
        designer: action.payload,
      };
    case DesignerActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case DesignerActionTypes.LOGOUT:
      return {
        designer: {},
        token: "",
      };
    default:
      return state;
  }
};
