import React, { useEffect, useRef, useState } from "react";
import {
  RiAddFill,
  RiFacebookFill,
  RiInstagramFill,
  RiPinterestFill,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import { useSidebarContext } from "../../contexts/dashboardContext";
import { FaEdit, FaGlobe } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCategories,
  getAllServices,
  getAllSkills,
  updateDesignerData,
} from "../../api/Api";
import { toast } from "react-toastify";
import { setDesigner } from "../../redux/actions/designerActions";
import SkillProgress from "../../components/common/SkillProgress";
import EditSkillsModal from "./EditSkillsModal";
import ProfileCover from "../../components/dashboardpanel/designer/ProfileCover";
import ProfileAvatar from "../../components/common/ProfileAvatar";
import AddSkillsModal from "./AddSkillsModal";
import Loader from "../../components/common/Loader";
import ImageGallery from "../../components/common/ImageGallery";
import VideoGallery from "../../components/common/VideoGallery";
import SingleImageWithDisplay from "../../components/common/SingleFileWithDisplay";

const showModal = (modalId) => {
  const modal = new window.bootstrap.Modal(document.getElementById(modalId));
  modal.show();
};

function DesignerProfileSettings() {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const sectionRef = useRef(null);
  const { sidebarOpened } = useSidebarContext();

  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [skills, setSkills] = useState([]);

  const [loading, setLoading] = useState();

  const { designer } = useSelector((state) => state.designerData);
  const { token: designerToken } = useSelector((state) => state.designerData);

  const [designerData, setDesignerData] = useState({
    portfolioSocials: {
      facebook: designer.portfolioSocials.facebook || "https://facebook.com/",
      instagram:
        designer.portfolioSocials.instagram || "https://instagram.com/",
      X: designer.portfolioSocials.X || "https://x.com/",
      pinterest:
        designer.portfolioSocials.pinterest || "https://pinterest.com/",
      website: designer.portfolioSocials.X || "",
    },
    coverImg: designer.coverImg || "",
    profileImg: designer.profileImg || "/assets/images/header-avatar.png",
    name: designer.name || "",
    username: designer.username || "",
    city: designer.city || "",
    country: designer.country || "",
    defineYourself: designer.defineYourself || "",
    bio: designer.bio || "",
    aboutMe: designer.aboutMe || "",
    phoneNumber: designer.phoneNumber || "",
    designerCategory: designer.designerCategory || [],
    skills: designer.skills || [],
    services: designer.services || [],
    resume: designer.resume || "",
    images: designer.images || [],
    videos: designer.videos || [],
    introVideo: designer.introVideo || "",
  });
  console.log(designer);
  useEffect(() => {
    getAllCategories().then((result) => {
      if (result.status === "success") {
        setCategories(result.data);
      }
    });

    getAllServices().then((result) => {
      if (result.status === "success") {
        setServices(result.data);
      }
    });

    getAllSkills().then((result) => {
      if (result.status === "success") {
        setSkills(result.data);
      }
    });
  }, []);

  const handleInputChange = async (e) => {
    const { name, value, type } = e.target;
    let newValue = value;

    if (type === "checkbox") {
      if (!designerData[name].includes(value)) {
        newValue = [...designerData[name], value];
      } else {
        newValue = designerData[name].filter(
          (selectedOne) => selectedOne !== newValue
        );
      }
    }

    const [newName, fieldName] = name.split(".");

    if (fieldName) {
      const username = newValue.split(".com/")[1];
      return setDesignerData((oldDesigner) => ({
        ...oldDesigner,
        [newName]: {
          ...oldDesigner[newName],
          [fieldName]:
            fieldName === "website"
              ? newValue
              : `https://${fieldName.toLowerCase()}.com/${username || ""}`,
        },
      }));
    }

    setDesignerData((oldDesigner) => ({
      ...oldDesigner,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = await updateDesignerData(
      {
        ...designerData,
        portfolioSocials: {
          facebook:
            designerData.portfolioSocials.facebook.split(".com/")[1] === ""
              ? ""
              : designerData.portfolioSocials.facebook,
          instagram:
            designerData.portfolioSocials.instagram.split(".com/")[1] === ""
              ? ""
              : designerData.portfolioSocials.instagram,
          X:
            designerData.portfolioSocials.X.split(".com/")[1] === ""
              ? ""
              : designerData.portfolioSocials.X,
          pinterest:
            designerData.portfolioSocials.pinterest.split(".com/")[1] === ""
              ? ""
              : designerData.portfolioSocials.pinterest,
          website: designerData.portfolioSocials.website,
        },
      },
      designerToken
    );

    if (res.status === "success") {
      dispatch(setDesigner(res.data));
      toast.success("Profile Updated Successfully!");
    } else {
      toast.error(res.errors?.[0].msg ?? res.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    const checkWidth = () => {
      if (sectionRef?.current) {
        setIsMobile(sectionRef?.current.offsetWidth < 768);
      }
    };

    if (sectionRef?.current) {
      setTimeout(() => setIsMobile(sectionRef?.current.offsetWidth < 768), 100);
    }

    window.addEventListener("resize", checkWidth);

    return () => {
      window.removeEventListener("resize", checkWidth);
    };
  }, [sidebarOpened]);

  useEffect(() => {
    function setRange(e) {
      const input = e.target;
      if (input.value.length) {
        input.setSelectionRange(input.value.length, input.value.length);
        setTimeout(() => {
          input.setSelectionRange(input.value.length, input.value.length);
        }, 0);
      }
    }

    const inputs = document.querySelectorAll(
      ".designer-profile-settings input[type='url']"
    );

    inputs.forEach((input) => {
      input.addEventListener("focus", setRange);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("focus", setRange);
      });
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="dashboard-section designer-profile-settings"
    >
      <div
        className="d-flex gap-2 flex-wrap justify-content-between"
        style={{ padding: "19px 24px", marginBottom: 5 }}
      >
        <h4
          className="text-nowrap"
          style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
        >
          PROFILE SETTINGS
        </h4>
        <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
          <li className="fs-14 breadcrumb-item">
            <Link to="/designer/dashboard">Dashboard</Link>
          </li>
          <li className="fs-14 breadcrumb-item active">Profile</li>
        </ol>
      </div>
      <div className="section-spacing">
        <form onSubmit={handleSubmit}>
          <div
            className={`profile-settings-grid ${isMobile ? "mobile" : ""}`}
            style={{ marginBottom: 19 }}
          >
            <div className="position-relative mx-n4 mt-n4 profile-cover-container">
              <ProfileCover
                data={designerData}
                setData={setDesignerData}
                token={designerToken}
              />
            </div>
            <div className="card mb-0 profile-avatar-card">
              <div className="card-body p-4 text-center">
                <ProfileAvatar
                  data={designerData}
                  setData={setDesignerData}
                  token={designerToken}
                />
                <h5 className="fs-16 mb-1">{designerData.name}</h5>
                <p className="text-muted fs-14 mb-0">
                  {designerData.city}, {designerData.country}
                </p>
              </div>
            </div>
            <div className="card mb-0 profile-socials-card">
              <div className="card-body">
                <h5 className="card-title mb-3">Portfolio/Socials</h5>
                <div className="mb-3 d-flex gap-2 aling-items-center">
                  <label
                    htmlFor="designer-profile-facebook"
                    className="mb-0 d-flex align-items-center justify-content-center rounded-circle bg-body-alt flex-grow-1 flex-shrink-0"
                    style={{ width: 32, height: 32 }}
                  >
                    <RiFacebookFill size={16} />
                  </label>
                  <input
                    type="url"
                    id="designer-profile-facebook"
                    className="form-control fs-14 fs-14"
                    name="portfolioSocials.facebook"
                    value={designerData.portfolioSocials.facebook}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3 d-flex gap-2 aling-items-center">
                  <label
                    htmlFor="designer-profile-instagram"
                    className="mb-0 d-flex align-items-center justify-content-center rounded-circle bg-body-alt flex-grow-1 flex-shrink-0"
                    style={{ width: 32, height: 32 }}
                  >
                    <RiInstagramFill size={16} />
                  </label>
                  <input
                    type="url"
                    id="designer-profile-instagram"
                    className="form-control fs-14 fs-14"
                    name="portfolioSocials.instagram"
                    value={designerData.portfolioSocials.instagram}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3 d-flex gap-2 aling-items-center">
                  <label
                    htmlFor="designer-profile-X"
                    className="mb-0 d-flex align-items-center justify-content-center rounded-circle bg-body-alt flex-grow-1 flex-shrink-0"
                    style={{ width: 32, height: 32 }}
                  >
                    <FaXTwitter size={16} />
                  </label>
                  <input
                    type="url"
                    id="designer-profile-X"
                    className="form-control fs-14 fs-14"
                    name="portfolioSocials.X"
                    value={designerData.portfolioSocials.X}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3 d-flex gap-2">
                  <label
                    htmlFor="designer-profile-pinterest"
                    className="mb-0 d-flex align-items-center justify-content-center rounded-circle bg-body-alt flex-grow-1 flex-shrink-0"
                    style={{ width: 32, height: 32 }}
                  >
                    <RiPinterestFill size={16} />
                  </label>
                  <input
                    type="url"
                    id="designer-profile-pinterest"
                    className="form-control fs-14 fs-14"
                    name="portfolioSocials.pinterest"
                    value={designerData.portfolioSocials.pinterest}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex gap-2">
                  <label
                    htmlFor="designer-profile-website"
                    className="mb-0 d-flex align-items-center justify-content-center rounded-circle bg-body-alt flex-grow-1 flex-shrink-0"
                    style={{ width: 32, height: 32 }}
                  >
                    <FaGlobe size={16} />
                  </label>
                  <input
                    type="url"
                    id="designer-profile-website"
                    className="form-control fs-14 fs-14"
                    name="portfolioSocials.website"
                    placeholder="https://yourwebsite.com"
                    value={designerData.portfolioSocials.website}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="card mb-0 designer-categories-card">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <h5 className="card-title w-100 mb-0">Designer Categories</h5>
                </div>
                <div className="d-flex gap-2 flex-wrap">
                  {categories.map((category) => {
                    return (
                      <label
                        className="form-check flex-grow-1"
                        style={{ flexBasis: "calc(50% - .5rem)" }}
                        htmlFor={category._id}
                        key={category._id}
                      >
                        <input
                          id={category._id}
                          className="form-check-input"
                          type="checkbox"
                          name="designerCategory"
                          value={category.name}
                          checked={designerData.designerCategory.includes(
                            category.name
                          )}
                          onChange={handleInputChange}
                        />
                        {category.name}
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="card mb-0 profile-services-card">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <h5 className="card-title w-100 mb-0">Choose Services</h5>
                </div>
                <div className="d-flex gap-2 flex-wrap">
                  {services.map((service) => {
                    return (
                      <label
                        className="form-check flex-grow-1"
                        style={{ flexBasis: "calc(50% - .5rem)" }}
                        htmlFor={service._id}
                        key={service._id}
                      >
                        <input
                          id={service._id}
                          className="form-check-input"
                          type="checkbox"
                          name="services"
                          value={service.name}
                          checked={designerData.services.includes(service.name)}
                          onChange={handleInputChange}
                        />
                        {service.name}
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="card mb-0 mt-xxl-n5 profile-inputs-card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="card-title mb-0">Profile Details</h5>
                <div className="d-flex gap-2">
                  <Link to={`/designer-profile/${designer?._id}`}>
                    <button
                      type="submit"
                      className={`d-flex align-items-center gap-1 btn btn-primary`}
                    >
                      View profile
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className={`d-flex align-items-center gap-1 btn btn-primary`}
                    disabled={loading}
                  >
                    {loading ? <Loader /> : "Save Changes"}
                  </button>
                </div>
              </div>
              <div className="card-body p-3 p-sm-4">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control fs-14"
                        name="name"
                        id="name"
                        onChange={handleInputChange}
                        value={designerData.name}
                        placeholder="John Doe"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="defineYourself" className="form-label">
                        Define Yourself
                      </label>
                      <select
                        className="form-control fs-14"
                        name="defineYourself"
                        id="defineYourself"
                        onChange={handleInputChange}
                        value={designerData.defineYourself}
                        required
                      >
                        <option value="" disabled selected>
                          Select Category
                        </option>
                        {categories.map((category) => {
                          return (
                            <option value={category.name} key={category._id}>
                              {category.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label">
                        Username
                      </label>
                      <input
                        type="text"
                        id="username"
                        onChange={handleInputChange}
                        value={designerData.username}
                        className="form-control fs-14"
                        name="username"
                        placeholder="Username"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        onChange={handleInputChange}
                        value={designer.email}
                        className="form-control fs-14"
                        name="email"
                        placeholder="johndoe@hotmail.com"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">
                        City
                      </label>
                      <input
                        type="text"
                        id="city"
                        onChange={handleInputChange}
                        value={designerData.city}
                        className="form-control fs-14"
                        name="city"
                        placeholder="Lisbon"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="country" className="form-label">
                        Country
                      </label>
                      <input
                        type="text"
                        id="country"
                        onChange={handleInputChange}
                        value={designerData.country}
                        className="form-control fs-14"
                        name="country"
                        placeholder="Portugal"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="phoneNumber" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        id="phoneNumber"
                        onChange={handleInputChange}
                        value={designerData.phoneNumber}
                        className="form-control fs-14"
                        name="phoneNumber"
                        placeholder="+(1) 987 541 6543"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <SingleImageWithDisplay
                      inputName="resume"
                      labelJsx={
                        <React.Fragment>
                          Resume <small>(pdf, docx, Less than 2Mb)</small>
                        </React.Fragment>
                      }
                      accept="image"
                      clientData={designerData}
                      setClientData={setDesignerData}
                      token={designerToken}
                    />
                  </div>
                  <div className="col-12">
                    <SingleImageWithDisplay
                      inputName="introVideo"
                      labelJsx={
                        <React.Fragment>
                          Introductory Video{" "}
                          <small>(mp4, mkv, Less than 10Mb)</small>
                        </React.Fragment>
                      }
                      clientData={designerData}
                      accept="video"
                      setClientData={setDesignerData}
                      token={designerToken}
                    />
                  </div>
                  <div className="col-12">
                    <ImageGallery
                      clientData={designerData}
                      setClientData={setDesignerData}
                      token={designerToken}
                      mode="designer"
                    />
                  </div>
                  <div className="col-12">
                    <VideoGallery
                      clientData={designerData}
                      setClientData={setDesignerData}
                      token={designerToken}
                      mode="designer"
                    />
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3 pb-2">
                      <label htmlFor="aboutMe" className="form-label">
                        About Me
                      </label>
                      <textarea
                        id="aboutMe"
                        onChange={handleInputChange}
                        value={designerData.aboutMe}
                        className="form-control fs-14"
                        name="aboutMe"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                  <div className={`col-lg-12 d-flex flex-column gap-2`}>
                    <div className="d-flex gap-2 justify-content-between align-items-center mb-3">
                      <p className="fs-14 fw-semibold m-0">Skills</p>
                      <button
                        type="button"
                        className="btn background-none p-0 d-flex align-items-center fs-14"
                        onClick={() => showModal("profile-setting-add-skills")}
                      >
                        <RiAddFill /> Add New Skills
                      </button>
                    </div>
                    <div className="d-flex flex-column gap-4">
                      {designerData.skills.length !== 0 ? (
                        designerData.skills.map((skill, i) => {
                          return (
                            <React.Fragment key={`${skill.name}-${i}`}>
                              <SkillProgress
                                initialRating={skill.rating}
                                skill={skill}
                                skillsData={designerData.skills}
                                editButton={
                                  <button
                                    type="button"
                                    className="btn border-0 p-0 d-flex align-items-center"
                                    onClick={() =>
                                      showModal(
                                        `profile-setting-edit-skills-${i}`
                                      )
                                    }
                                  >
                                    <FaEdit />
                                  </button>
                                }
                              />
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <p className="fs-14 m-0">No Skills Added.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <AddSkillsModal
          skillsOptions={skills}
          designerData={designerData}
          setDesignerData={setDesignerData}
        />
        {designerData.skills.map((skill, i) => {
          return (
            <React.Fragment key={`${skill.name}-${i}`}>
              <EditSkillsModal
                skillsOptions={skills}
                designerData={designerData}
                setDesignerData={setDesignerData}
                selectedIndex={i}
              />
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
}

export default DesignerProfileSettings;
