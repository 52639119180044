import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSaveProject, unSavePortfolio } from "../../api/Api";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

function UserSavedProjects() {
  const [savedProjects, setSavedProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const userToken = useSelector((state) => state.userData.token);

  useEffect(() => {
    const fetchSavedProjects = async () => {
      setLoading(true);
      const res = await getSaveProject(userToken);

      if (res.status === "success") {
        setSavedProjects(res.data);
        setFilteredProjects(res.data);
      } else {
        toast.error("Failed to fetch saved projects.");
      }
      setLoading(false);
    };

    fetchSavedProjects();
    // eslint-disable-next-line
  }, []);
  const handleUnsave = async (id) => {
    setLoading(true);
    try {
      const result = await unSavePortfolio(id, userToken);
      if (result.status === "success") {
        toast.success(result.message || "Portfolio Unsaved");
        setSavedProjects(savedProjects.filter((project) => project.portfolioId._id !== id));
        setFilteredProjects(filteredProjects.filter((project) => project.portfolioId._id !== id));
      } else {
        toast.error(result.message || "Failed to Unsaved.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const result = savedProjects.filter((project) =>
      project.portfolioId.title.toLowerCase().includes(search.toLowerCase()) ||
      project.portfolioId.designerName.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredProjects(result);
  }, [search, savedProjects]);

  const columns = [
    {
      name: "Sr #",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
    },
    {
      name: "Title",
      selector: (row) => (
        <Link to={`/designer-profile/${row?.portfolioId?.designerId?._id}`}
        state={{ fromHomepage: true, activeTab: "projects" }}>
          {row?.portfolioId?.title}
        </Link>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Designer Name",
      selector: (row) => row?.portfolioId?.designerId?.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          style={{ fontSize: 12.25 }}
          onClick={() => handleUnsave(row.portfolioId._id)}
          className="text-center btn btn-sm btn-danger"
        >
          Unsave
        </button>
      ),
      width: "fit-content",
    },
  ];

  return (
    <section className="dashboard-section user-saved-projects">
      <div
        className="d-flex gap-2 flex-wrap justify-content-between align-items-center"
        style={{ padding: "19px 24px", marginBottom: 5 }}
      >
        <h4
          className="text-nowrap"
          style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
        >
          SAVED PROJECTS
        </h4>
        <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
          <li className="fs-14 breadcrumb-item">
            <Link to="/user/dashboard">Dashboard</Link>
          </li>
          <li className="fs-14 breadcrumb-item active">Saved Projects</li>
        </ol>
      </div>
      <div className="section-spacing">
        <div className="card saved-projects-card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="card-title mb-0 flex-grow-1">Your Saved Projects</h5>
            <input
              type="text"
              className="form-control"
              placeholder="Search by Title or Designer Name"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ maxWidth: "250px", height: "28px" }}
            />
          </div>
          <div className="card-body fs-14">
            <DataTable
              columns={columns}
              data={filteredProjects}
              progressPending={loading}
              pagination
              highlightOnHover
              striped
              responsive
              persistTableHead
              noDataComponent="No Saved Projects Found."
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default UserSavedProjects;
