import React, { useEffect, useState } from 'react';
import { DangerSignIcon } from "../../utils/icons";
import { getUserDashboard, getUserData, sendEmailVerificationLink } from "../../api/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";


function UserDashboard() {
 const [userData, setUserData] = useState();
 const [isSendingEmail, setIsSendingEmail] = useState();
 const { token: userToken } = useSelector(state => state.userData);
 const [dashboardData, setDashboardData] = useState({});

 const { user } = useSelector(state => state.userData);

 useEffect(() => {
  const fetchUserData = async () => {
   const result = await getUserData(userToken);

   if (result.status === "success") {
    setUserData(result.data);
   }
  };

  fetchUserData();
  // eslint-disable-next-line
 }, []);

 const handleSendVerificationLink = async () => {
  setIsSendingEmail(true);
  const result = await sendEmailVerificationLink(user.email, "user");

  if (result.status === "success") {
   toast.success("Email Verification Link Sent");
  } else {
   toast.error("Error Sending Verification Link");
  }
  setIsSendingEmail(false);
 };

 useEffect(() => {
    const fetchDashboardData = async () => {
      const res = await getUserDashboard(user._id);
      if (res.status === "success") {
        setDashboardData(res.data);
      }

    };

    fetchDashboardData();
    // eslint-disable-next-line
  }, []);
 return (
  <>
   <section className="dashboard-section user-dashboard">
    <div style={{ padding: "19px 24px", marginBottom: 5 }}>
     <h4 style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}>DASHBOARD</h4>
    </div>
    <div className="section-spacing">

     {(user?.emailVerified || userData?.emailVerified) ? <div className="row">
      <div className="col-xxl-5">

       <div className="d-flex flex-column h-100">
        <div className="row fs-14">
         <div className="col-md-6">
          <div className="card card-animate">
           <div className="card-body">
            <div className="d-flex justify-content-between">
             <div>
              <p className="fw-medium text-muted mb-0">Saved Projects</p>
              <h4 className="mt-4 fs-22 ff-secondary fw-semibold">
               <span className="counter-value" data-target="118">{dashboardData?.savedProjectCount}</span>
              </h4>
             </div>
             <div>
              <div className="avatar-sm flex-shrink-0">
               <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bookmark text-info">
                 <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                </svg>
               </span>
              </div>
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
       
      </div>
     </div> : <div className="card">
      <div className="card-body p-0">
       <div className="alert alert-warning border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
        <DangerSignIcon color="rgb(239, 174, 78)" className="flex-shrink-0" />
        <div className="flex-grow-1 text-truncate" style={{ width: 0 }}>
         <span className="fw-semibold fs-14">Welcome! To unlock the full potential of this platform, please verify your email address. We've sent a verification link to your inbox.</span>
        </div>
       </div>
       <p className="fs-16 p-3">
        Open your inbox to find the email verification link or <button className="btn btn-link text-decoration-none p-0 text-warning fw-semibold" onClick={handleSendVerificationLink} disabled={isSendingEmail}>Click Here</button> to generate a new verification link.
       </p>
      </div>
     </div>}

    </div>
   </section>
  </>
 );
}

export default UserDashboard;
