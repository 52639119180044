import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { updateEmailVerifiedStatus } from "../api/Api";
import { setDesigner } from "../redux/actions/designerActions";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/actions/userActions";

function DesignerVerify() {
  const dispatch = useDispatch();

  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleVerification = async () => {
    setLoading(true);

    try {
      const result = await updateEmailVerifiedStatus(token);
      if (result.status === "success") {
        setMessage("Verification Successful! Redirecting...");
        if (result.data.role === "user") {
         dispatch(setUser(result.data));
         navigate("/user/dashboard");
        } else {
         dispatch(setDesigner(result.data));
         navigate("/designer/dashboard");
        }
      } else {
        setMessage(result.message || "Verification failed.");
      }
    } catch (error) {
      setMessage("An error occurred during verification. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleVerification();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="vh-100 d-flex justify-content-center align-content-center align-items-center">
      {loading ? (
        <div>
          <div className="fw-bold fs-1">Verifying...</div>
        </div>
      ) : (
        <p className="fw-bold fs-1">{message}...</p>
      )}
    </div>
  );
}

export default DesignerVerify;
