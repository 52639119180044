import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import 'ckeditor5/ckeditor5.css';
import store, { Persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import SidebarContextProvider from "./contexts/dashboardContext";
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={false} persistor={Persistor}>
        <SidebarContextProvider>
         <App />
        </SidebarContextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
