export const UserActionTypes = {
 SET_USER: "SET_USER",
 SET_TOKEN: "SET_USER_TOKEN",
 LOGOUT: "USER_LOGOUT"
};

export const DesignerActionTypes = {
 SET_DESIGNER: "SET_DESIGNER",
 SET_TOKEN: "SET_DESIGNER_TOKEN",
 LOGOUT: "DESIGNER_LOGOUT"
};

export const AdminActionTypes = {
 SET_TOKEN: "SET_ADMIN_TOKEN",
 LOGOUT: "ADMIN_LOGOUT"
};

export const GeneralStateActionTypes = {
 SET_IS_TOKEN_INITIALIZED: "SET_IS_TOKEN_INITIALIZED",
};