import React from "react";
import Breadcrumb from "./Breadcrumb";
import { Link } from "react-router-dom";

function Main() {
  return (
    <div className="container">
      <div className="mt-5 pt-3">
        <Breadcrumb />
        <div className="d-flex justify-content-center mt-5">
          <img
            className="w-75 about-img"
            src="assets/images/about.avif"
            alt="About Us"
          />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <span className="w-75 mb-2">
          Welcome to{" "}
          <Link to={"/"} className="fw-semibold">
            iamadesigner.com
          </Link>{" "}
          – your new creative home! We believe that every designer has a story
          to tell, and we’ve built a space where that story can truly shine.
          Whether you're a jewelry designer, fashion designer, shoes designer,
          or anything in between,{" "}
          <Link to={"/"} className="fw-semibold">
            iamadesigner.com
          </Link>{" "}
          is designed to be the perfect platform for showcasing your work,
          sharing your journey, and connecting with brands and employers who are
          looking for creative talent just like you. <br /> At{" "}
          <Link to={"/"} className="fw-semibold">
            iamadesigner.com
          </Link>{" "}
          , we know that design is so much more than just a job – it’s a way of
          thinking, a form of expression, and a big part of who you are. That’s
          why we’ve made it easy for you to create a profile that’s uniquely
          yours, showcasing not just your portfolio but also your personal story
          and your professional journey. Whether you’re a seasoned pro or just
          starting out, this is the place where your creativity can take center
          stage.
        </span>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <span className="w-75 mb-2">
          <h5 className="fw-semibold">For Designers</h5>
          Here, you’re in the spotlight. With a sleek, user-friendly interface,
          you can easily build a profile that shows off your best work,
          highlights your experience, and tells your story in a way that feels
          authentic to you. Upload your resume, share your latest projects, and
          let your creativity shine through every corner of your profile. It’s
          your chance to be discovered by companies and brands that are looking
          for your unique design talents. <br />
          We’ve designed{" "}
          <Link to={"/"} className="fw-semibold">
            iamadesigner.com
          </Link>{" "}
          to be as intuitive as possible, so you can focus on what you do best –
          creating. Updating your profile is quick and simple, so whether you’re
          adding a new project to your portfolio or tweaking your bio, it’s
          always easy to keep your online presence fresh and engaging. We’re
          here to help you showcase your best work, tell your story, and connect
          with new opportunities in the design world.
        </span>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <span className="w-75 mb-2">
          <h5 className="fw-semibold">For Employers</h5>
          We know that finding the right designer can sometimes feel like
          searching for a needle in a haystack. That’s why we’ve made it our
          mission to simplify the process. At{" "}
          <Link to={"/"} className="fw-semibold">
            iamadesigner.com
          </Link>{" "}
          , you’ll find a community of talented, creative designers ready to
          help bring your vision to life. Whether you're looking for a freelance
          designer for a one-off project or searching for a long-term hire, our
          platform is here to connect you with the best and brightest in the
          design world. <br />
          With detailed profiles, resumes, and portfolios all in one place, it’s
          easy to find the perfect designer for your needs. Our advanced search
          features make it simple to narrow down your options and discover the
          right creative talent to fit your brand’s aesthetic and goals. And
          because we know time is valuable, we’ve streamlined the entire process
          so that connecting with the right designer is fast, easy, and
          stress-free.
        </span>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <span className="w-75 mb-2">
          <h5 className="fw-semibold">Why Us?</h5>
          At{" "}
          <Link to={"/"} className="fw-semibold">
            iamadesigner.com
          </Link>{" "}
          , we’re passionate about design, and we’re even more passionate about
          helping designers and companies connect. We understand the importance
          of finding the right match, whether it’s for your next project or the
          next big step in your career. That’s why our platform is built around
          simplicity, efficiency, and creativity. <br />
          Everything about{" "}
          <Link to={"/"} className="fw-semibold">
            iamadesigner.com
          </Link>{" "}
          is designed with you in mind – whether you’re a designer or an
          employer. Our platform is smooth, intuitive, and easy to navigate,
          ensuring that your experience is as seamless as possible. We take care
          of the little details so you can focus on what really matters:
          showcasing your creativity or discovering new talent.
        </span>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <span className="w-75 mb-2">
          <h5 className="fw-semibold">Our Vision</h5>
          We believe that design has the power to transform the world. At {" "} 
          <Link to={"/"} className="fw-semibold">
            iamadesigner.com
          </Link>{" "}
          , our vision is to create a vibrant community where designers and
          employers come together to collaborate, innovate, and make amazing
          things happen. We want to inspire creativity, spark new ideas, and
          help designers of all kinds connect with the opportunities they
          deserve. Whether you're here to show your work, find new
          opportunities, or hire talented designers, we're excited to be a part
          of your journey. At{" "}
          <Link to={"/"} className="fw-semibold">
            iamadesigner.com
          </Link>{" "}
          , we’re more than just a platform – we’re a community where creativity
          thrives, and connections are made. So go ahead, explore the
          possibilities. We can’t wait to see what you create and how we can
          help you take your design career to the next level. Welcome to{" "}
          <Link to={"/"} className="fw-semibold">
            iamadesigner.com
          </Link>{" "}
          , where creativity meets opportunity.
        </span>
      </div>
    </div>
  );
}

export default Main;
