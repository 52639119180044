import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateDesignerData } from "../../api/Api";
import { RiCloseFill } from "react-icons/ri";
import Loader from "../../components/common/Loader";
import SkillProgress from "../../components/common/SkillProgress";
import { setDesigner } from "../../redux/actions/designerActions";
import DeleteSkillDialog from "./DeleteSkillDialog";

const showModal = (modalId) => {
 const modal = new window.bootstrap.Modal(document.getElementById(modalId));
 modal.show();
};

const EditSkillsModal = ({ skillsOptions, designerData, setDesignerData, selectedIndex }) => {
 const dispatch = useDispatch();

 const [loading, setLoading] = useState();
 const [skillsData, setSkillsData] = useState(designerData.skills.filter((_, i) => i === selectedIndex));

 const [suggestedSkills, setSuggestedSkills] = useState([]);

 useEffect(() => {
  setSuggestedSkills(skillsOptions.map(skill => skill.name).filter(skillName =>
   !designerData.skills.some(s => s.name === skillName)
  ));
  // eslint-disable-next-line
 }, [skillsOptions, designerData]);

 const { token: designerToken } = useSelector((state) => state.designerData);

 const handleInputChange = (e, rating, i, skillTaken) => {
  if (rating || i) {
   const newSkillsData = skillsData.slice();
   newSkillsData[i].rating = rating;
   setSkillsData(newSkillsData);
   return;
  }

  setSkillsData([{ name: skillTaken || e.target.value, rating: 1 }]);
  setSuggestedSkills([...suggestedSkills.filter(name => name !== (skillTaken || e.target.value)), skillsData[0].name]);

  e.target.value = "";
 };

 const handleSubmit = async (e) => {
  e.preventDefault();

  setLoading(true);

  const temp = designerData.skills.slice();
  temp[selectedIndex] = skillsData[0];

  const result = await updateDesignerData(
   { skills: temp },
   designerToken
  );

  if (result.status === "success") {
   toast.success("Skills Updated Successfully");
   const modal = document.getElementById(`profile-setting-edit-skills-${selectedIndex}`);
   const modalInstance = window.bootstrap.Modal.getInstance(modal);
   modalInstance.hide();
   dispatch(setDesigner(result.data));
   setDesignerData((oldDesignerData) => ({
    ...oldDesignerData,
    skills: temp,
   }));
   setSuggestedSkills(suggestedSkills.filter(skill =>
    !result.data.skills.some(s => s.name === skill)
   ));
  } else {
   toast.error(result.errors?.[0].msg);
  }
  setLoading(false);
 };

 const handleSkillDelete = async () => {
  const result = await updateDesignerData(
   { skills: designerData.skills.filter((_, i) => i !== selectedIndex) },
   designerToken
  );

  if (result.status === "success") {
   toast.success("Skill Delete Successfully");
   dispatch(setDesigner(result.data));
   setDesignerData((oldDesignerData) => ({
    ...oldDesignerData,
    skills: designerData.skills.filter((_, i) => i !== selectedIndex),
   }));
  } else {
   toast.error(result.errors?.[0].msg);
  }
 };

 return (
  <>
   <div
    className="modal fade"
    id={`profile-setting-edit-skills-${selectedIndex}`}
    tabIndex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
   >
    <form onSubmit={handleSubmit}>
     <div className="modal-dialog mx-auto" style={{ width: "80%", maxWidth: 700 }}>
      <div className="modal-content container">
       <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
         Edit Skill
        </h5>
        <button
         type="button"
         className="btn background-none p-0 position-absolute"
         style={{ right: 16 }}
         data-bs-dismiss="modal"
         aria-label="Close"
         onClick={() => setSkillsData(designerData.skills.filter((_, i) => i === selectedIndex))}
        ><RiCloseFill /></button>
       </div>
       <div className="modal-body mb-4 pt-0">
        <div className="row mt-4">
         <div className="flex-grow-1">
          <select
           name="skills"
           className="form-control w-100"
           // disabled={skillsData.length === maxSkills - designerData.skills.length}
           value={skillsData[0].name}
           onChange={e => handleInputChange(e)}
          >
           <option value="" disabled selected>
            Select Skill
           </option>
           {skillsOptions.map(skill => (
            <option key={skill._id} value={skill.name}>
             {skill.name}
            </option>
           ))}
          </select>
         </div>
        </div>
        <div className="row my-4">
         {skillsData.map((skill, index) => (
          <div key={`${skill.name}-${index}`} className="col-md-6">
           <SkillProgress
            skill={skill}
            onRatingChange={(rating) => handleInputChange(undefined, rating, index)}
            skillsData={skillsData}
           />
          </div>
         ))}
        </div>
        <div className="row mt-4">
         <h6>Suggested Skills:</h6>
         <div className="d-flex flex-wrap gap-2">
          {suggestedSkills.map((skill, index) => (
           <button
            key={index}
            type="button"
            className="btn btn-outline-primary btn-sm suggested-skill-button"
            onClick={(e) => handleInputChange(e, undefined, undefined, skill)}
           >
            {skill}
           </button>
          ))}
         </div>
        </div>
       </div>
       <div className="modal-footer px-0 py-2">
        <button
         type="button"
         className="d-flex align-items-center gap-1 btn btn-sm btn-link"
         style={{ color: "#f7666e" }}
         onClick={() => {
          const modal = document.getElementById(`profile-setting-edit-skills-${selectedIndex}`);
          const modalInstance = window.bootstrap.Modal.getInstance(modal);
          modalInstance.hide();
          showModal(`delete-skill-dialog-${selectedIndex}`);
         }}
        >
         Delete
        </button>
        <button
         type="button"
         className="d-flex align-items-center gap-1 btn btn-sm btn-danger ms-auto"
         data-bs-dismiss="modal"
         onClick={() => setSkillsData(designerData.skills.filter((_, i) => i === selectedIndex))}
        >
         Close
        </button>
        <button type="submit" className={`d-flex align-items-center gap-1 btn btn-sm btn-primary`} disabled={loading}>
         {loading ? <Loader /> : "Confirm"}
        </button>
       </div>
      </div>
     </div>
    </form>
   </div>
   <DeleteSkillDialog selectedIndex={selectedIndex} handleSkillDelete={handleSkillDelete} />
  </>
 );
};

export default EditSkillsModal;
