import React from "react";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <>
    <div className="img-hero-container">
      <img src="/assets/images/heroimg.jpeg" alt="Hero" className="img-hero" />
      <div className="logo-container d-none d-md-block">
        <div className="text-center">
        <img
          src="/assets/images/footerlogo.png"
          width={"300"}
          alt="Logo"
          className="logo-img"
        />
        </div>
        <div className="d-flex justify-content-center">
          <div className="text-center text-white">
            <h6>Showcasing Designers. Connecting Opportunities.</h6>
            <Link
              to={"/designers"}
              className="btn rounded-3 px-5 py-2 custom-btn text-white my-3"
            >
              Find Dream Designers
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="d-md-none d-block">
        <div className="text-center">
        <img
          src="/assets/images/footerlogo.png"
          width={"250"}
          alt="Logo"
          className="logo-img"
        />
        </div>
        <div className="d-flex justify-content-center">
          <div className="text-center text-black">
            <h6>Showcasing Designers. Connecting Opportunities.</h6>
            <Link
              to={"/designers"}
              className="btn rounded-3 px-5 py-2 custom-btn text-white my-3"
            >
              Find Dream Designers
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
