import React, { useEffect, useState } from "react";
import Card from "./Card";
import { getlatestFeaturedPortfolio } from "../../../api/Api";
import { Link } from "react-router-dom";

function CardsExplore() {
 const [designerRows, setDesignerRows] = useState([]);
 const [loading, setLoading] = useState(false);

 useEffect(() => {
  const fetchDesigners = async () => {
   setLoading(true);
   const res = await getlatestFeaturedPortfolio();

   if (res.status === "success") {
    setDesignerRows(res.data);
   }
   setLoading(false);
  };

  fetchDesigners();
  // eslint-disable-next-line
 }, []);

 if (loading) {
  return (
   <div className="d-flex justify-content-center my-5">
    <h2>Loading...</h2>
   </div>
  );
 }


 return (
  <>
   <div className="row mb-4">
    {designerRows.map((portfolio) => (
     <Link className="col-md-3 mb-4"
      key={portfolio._id}
      to={`/designer-profile/${portfolio?.designer?._id}`}
      state={{ fromHomepage: true }}
     >
      <Card
       id={portfolio?._id}
       img={portfolio?.designer?.coverImg}
       userName={portfolio?.designer?.name}
       userimg={portfolio?.designer?.profileImg}
       hovertext={portfolio?.title}
       subTitle={portfolio?.subtitle}
      />
     </Link>
    ))}
   </div>
  </>
 );
}

export default CardsExplore;
