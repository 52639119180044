import { GeneralStateActionTypes } from "../constants/actionTypes";

const initialState = {
 isTokenInitialized: false,
};

export const generalStateReducer = (state = initialState, action) => {
 switch (action.type) {
  case GeneralStateActionTypes.SET_IS_TOKEN_INITIALIZED:
   return {
    isTokenInitialized: action.payload,
   };
  default:
   return state;
 }
};
