import React, { useState } from "react";
import { adminLoginWithEmail } from "../../api/Api";
import { useDispatch } from "react-redux";
import { setAdminToken } from "../../redux/actions/adminActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AdminLogin() {
 const dispatch = useDispatch();
 const navigate = useNavigate();

 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");

 const handleSubmit = async (e) => {
  e.preventDefault();

  const res = await adminLoginWithEmail({
   email,
   password
  });

  if (res.status === "success") {
   dispatch(setAdminToken(res.authToken));
   toast.success("Login successful!");
   navigate("/admin");
  } else {
   toast.error(res.errors?.[0].msg ?? res.message);
  }
 };

 return (
  <>
   <section className="dashboard-section section-padding h-100 d-flex align-items-center justify-content-center">
    <div className="card p-4 pt-0 text-center m-0" style={{ maxWidth: 350 }}>
     <img src="/assets/images/logo-trans.png" alt="logo" className="w-100" />
     <form onSubmit={handleSubmit} className="d-flex flex-column gap-4 justify-content-center">
      <h3 className="mb-0">Administrator Login</h3>
      <div>
       <input
        name="email"
        type="email"
        className="form-control mb-2"
        placeholder="Email"
        value={email}
        onChange={e => setEmail(e.target.value)}
       />
       <input
        name="password"
        type="password"
        className="form-control"
        placeholder="Password"
        value={password}
        onChange={e => setPassword(e.target.value)}
       />
      </div>
      <button type="submit" className="btn btn-primary mb-3">Login</button>
     </form>
     <p><strong>Copyright</strong> I Am A Designer © 2024</p>
    </div>
   </section>
  </>
 );
}

export default AdminLogin;
