import React, { useEffect, useState } from "react";
import { getAllCategories, getAllServices, getAllSkills } from "../../api/Api";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

function ModelLeft({ 
 categoriesToFilter,
 setCategoriesToFilter,
 servicesToFilter,
 setServicesToFilter,
 skillsToFilter,
 setSkillsToFilter,
 }) {
 const [categories, setCategories] = useState([]);
 const [services, setServices] = useState([]);
 const [skills, setSkills] = useState([]);

 const [tempCategories, setTempCategories] = useState([]);
 const [tempServices, setTempServices] = useState([]);
 const [tempSkills, setTempSkills] = useState([]);
 
 useEffect(() => {
  setTempCategories(categoriesToFilter);
  setTempServices(servicesToFilter);
  setTempSkills(skillsToFilter);
  // eslint-disable-next-line
 }, [categoriesToFilter, servicesToFilter, skillsToFilter])

 const [openSections, setOpenSections] = useState({
  categories: false,
  services: false,
  skills: false
 });

 const handleApplyNow = () => {
  setCategoriesToFilter(tempCategories);
  setServicesToFilter(tempServices);
  setSkillsToFilter(tempSkills);
 };

 useEffect(() => {
   getAllCategories().then((result) => {
    if (result.status === "success") {
      setCategories(result.data);
    }
  });

  getAllServices().then((result) => {
    if (result.status === "success") {
      setServices(result.data);
    }
  });

  getAllSkills().then((result) => {
    if (result.status === "success") {
      setSkills(result.data);
    }
  });
 }, []);

 const handleCheckboxChange = (type, value) => {
  switch (type) {
   case 'categories':
    setTempCategories(prev =>
     prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
    );
    break;
   case 'services':
    setTempServices(prev =>
     prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
    );
    break;
   case 'skills':
    setTempSkills(prev =>
     prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
    );
    break;
   default: return null;
  }
 };

 const toggleSection = (section) => {
  setOpenSections(prev => ({
   ...prev,
   [section]: !prev[section]
  }));
 };

 const renderCollapsibleSection = (title, type, items, tempItems) => (
  <div className="mb-3">
   <h6 className="mb-3">
    <button
     type="button"
     className="btn w-100 text-decoration-none d-flex justify-content-between align-items-center"
     onClick={() => toggleSection(type)}
    >
     {title}
     {openSections[type] ? <FaChevronUp /> : <FaChevronDown />}
    </button>
   </h6>
   <div className={`collapse ${openSections[type] ? 'show' : ''}`}>
    <div className="d-flex gap-2 flex-wrap">
     {items.map((item) => (
      <div className="form-check" key={item.name} style={{ flexBasis: "calc(50% - .5rem)" }}>
       <input
        className="form-check-input"
        type="checkbox"
        id={`${type}-${item.name}`}
        checked={tempItems.includes(item.name)}
        onChange={() => handleCheckboxChange(type, item.name)}
       />
       <label className="form-check-label" htmlFor={`${type}-${item.name}`}>
        {item.name}
       </label>
      </div>
     ))}
    </div>
   </div>
  </div>
 );

 return (
  <div
   className="modal fade"
   id="exampleModal"
   tabIndex="-1"
   aria-labelledby="exampleModalLabel"
   aria-hidden="true"
  >
   <div className="modal-dialog modal-dialog-scrollable modal-left">
    <div className="modal-content full-height d-flex flex-column">
     <div className="modal-header">
      <h1 className="modal-title fs-4" id="exampleModalLabel">
       Filters
      </h1>
      <button
       type="button"
       className="btn-close"
       data-bs-dismiss="modal"
       aria-label="Close"
      ></button>
     </div>
     <div className="modal-body flex-grow-1 overflow-y-auto">
       <div className="d-flex flex-column">
        {renderCollapsibleSection("Designer Categories", "categories", categories, tempCategories)}
        {renderCollapsibleSection("Services", "services", services, tempServices)}
        {renderCollapsibleSection("Skills", "skills", skills, tempSkills)}
       </div>
     </div>
     <div className="modal-footer">
      <button
       className="btn btn-dark w-100"
       onClick={handleApplyNow}
       type="button"
       data-bs-dismiss="modal"
       aria-label="Close"
      >
       Apply Now
      </button>
     </div>
    </div>
   </div>
  </div>
 );
}

export default ModelLeft;