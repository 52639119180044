import React from "react";
import { Link } from "react-router-dom";

function FindDream() {
  return (
    <div className="bg-main">
      <div className="container">
        <div className=" py-5 text-center">
          <div className="row">
            <div className="col-lg-6 mx-auto ">
              <div className="text-center">
                <h1 className="finddream-text-font mb-4">
                  Find Your Dream Designer Today
                </h1>
                <p className="finddream-paragraph mb-4">
                  The world’s leading brands use 'I am a designer' to hire
                  creative talent. Browse thousands of top-rated designs &
                  portfolios to find your perfect creative match!
                </p>
                <Link
                  to={"/designers"}
                  className="rounded-5 p-3 px-5 btn bg-black fw-bold text-white mb-4"
                >
                  Get started now
                </Link>
                <div className="finddream-underline">
                  {" "}
                  Are you a designer?{" "}
                  <span>
                    {" "}
                    <Link to={"/register"}>
                      <u>Publish Your Portfolio</u>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindDream;
