import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  setDesignerToken,
  setDesigner,
} from "../../redux/actions/designerActions";
import { signUp, getDesignerData, getUserData } from "../../api/Api";
import { setUser, setUserToken } from "../../redux/actions/userActions";
import { setIsTokenInitialized } from "../../redux/actions/generalStateActions";

function RegisterScreen() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("designer");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    }

    if (password !== confirmpassword) {
      toast.error("Passwords do not match.");
      return;
    }

    setLoading(true);

    switch (userType) {
      case "designer":
        try {
          const result = await signUp({
            name,
            email,
            password,
            role: userType,
          });
          if (result.status === "success") {
            const designerData = await getDesignerData(result.data);
            dispatch(setDesigner(designerData.data));
            dispatch(setIsTokenInitialized(true));
            dispatch(setDesignerToken(result.data));
            toast.success(result.message || "Registration Successful!");
            setEmail("");
            setName("");
            setPassword("");
            setConfirmpassword("");
            navigate("/designer/dashboard");
          } else {
            toast.error(result.message || "Registration failed.");
          }
        } catch (error) {
          toast.error(
            "An error occurred during registration. Please try again."
          );
        } finally {
          setLoading(false);
        }
        break;

      case "user":
        try {
          const result = await signUp({
            name,
            email,
            password,
            role: userType,
          });
          if (result.status === "success") {
            const userData = await getUserData(result.data);
            dispatch(setUser(userData.data));
            dispatch(setIsTokenInitialized(true));
            dispatch(setUserToken(result.data));
            toast.success(result.message || "Registration Successful!");
            setEmail("");
            setName("");
            setPassword("");
            setConfirmpassword("");
          } else {
            toast.error(result.message || "Registration failed.");
          }
        } catch (error) {
          toast.error(
            "An error occurred during registration. Please try again."
          );
        } finally {
          setLoading(false);
        }
        break;
      default:
        toast.error("Invalid user type selected.");
    }

    setLoading(false);
  };

  return (
    <div className="d-flex justify-content-center align-items-center pt-3 my-5">
      <div className="text-center mt-5 designer-login-card">
        <div className="row">
          <div className="col-md-6 d-none d-md-block">
            <img
              className="w-100 h-100 contact-img "
              src="/assets/images/login-designer.jpg"
              alt="Designer Login"
            />
          </div>
          <div className="col-md-6 my-auto">
            <img
              className="pt-2"
              width={180}
              src="/assets/images/authlogo.png"
              alt="logo"
            />
            <div className="fs-2">Register</div>

            <div className="mx-4">
              <small className="mb-3">
                Register as a designer with us to share your designs with the
                world.
              </small>
              <form onSubmit={handleRegister}>
                <div className="my-3">
                  <p className="fs-14 text-start">I am a:</p>
                  <div className="d-flex gap-3">
                    <span>User</span>
                    <div className="form-check form-switch m-0 p-0 mt-1">
                      <input
                        className="form-check-input m-0 p-0"
                        type="checkbox"
                        id="userTypeToggle"
                        checked={userType === "designer"}
                        onChange={() =>
                          setUserType(
                            userType === "designer" ? "user" : "designer"
                          )
                        }
                      />
                    </div>
                    <span>Designer</span>
                  </div>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="name"
                    value={name}
                    className="form-control"
                    id="floatingInput"
                    placeholder="name"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label htmlFor="floatingInput">Name</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    className="form-control"
                    id="floatingInput2"
                    placeholder="name@example.com"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="floatingInput2">Email address</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    name="password"
                    value={password}
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label htmlFor="floatingPassword">Password</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    name="confirmpassword"
                    value={confirmpassword}
                    className="form-control"
                    id="confirmpassword"
                    placeholder="confirm Password"
                    required
                    onChange={(e) => setConfirmpassword(e.target.value)}
                  />
                  <label htmlFor="confirmpassword">Confirm Password</label>
                </div>
                <button
                  type="submit"
                  className="btn rounded-5 w-100 py-2 custom-btn text-white mb-3"
                  disabled={loading}
                >
                  {loading ? "REGISTERING..." : "REGISTER"}
                </button>
              </form>

              <div className="pb-3">
                <small className="text-muted ">
                  Already Have an Account?
                  <Link
                    to={"/login"}
                    className="text-decoration-underline text-muted"
                  >
                    {" "}
                    Login Now
                  </Link>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterScreen;
