import React, { useEffect, useState } from "react";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { Link } from "react-router-dom";
import { useSidebarContext } from "../../contexts/dashboardContext";
import { getAllContacts } from "../../api/Api";
import moment from "moment";
import DataTable from "react-data-table-component";

function Queries() {
  const { width } = useWindowSize();
  const { sidebarOpened } = useSidebarContext();

  const [designerRows, setDesignerRows] = useState([]);
  const [filteredDesigners, setFilteredDesigners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");


  useEffect(() => {
    const fetchQuery = async () => {
      setLoading(true);
      const res = await getAllContacts();

      if (res.status === "success") {
        setDesignerRows(res.data);
        setFilteredDesigners(res.data);
      }
      setLoading(false);
    };

    fetchQuery();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const result = designerRows.filter((designer) => {
      return (
        (designer.name &&
          designer.name.toLowerCase().includes(search.toLowerCase())) ||
        (designer.username &&
          designer.username.toLowerCase().includes(search.toLowerCase())) ||
        (designer.email &&
          designer.email.toLowerCase().includes(search.toLowerCase()))
      );
    });
    setFilteredDesigners(result);
  }, [search, designerRows]);

  useEffect(() => {
    const dashboardLayout = document.querySelector(".dashboard-layout");
    const card = document.querySelector(".designers-management-card");

    if (width < 768) {
      card.style.width = dashboardLayout.clientWidth - 39 + "px";
    } else if (!sidebarOpened) {
      card.style.width = dashboardLayout.clientWidth - 109 + "px";
    } else {
      card.style.width = dashboardLayout.clientWidth - 289 + "px";
    }
  }, [width, sidebarOpened]);

  const columns = [
    {
      name: "Sr #",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px"
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "200px"

    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
        name: "Message",
        selector: (row) => row.message,
        sortable: true,
      },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
    },
   
  ];

  return (
    <section className="dashboard-section admin-designers-management">
      <div
        className="d-flex gap-2 flex-wrap justify-content-between align-items-center"
        style={{ padding: "19px 24px", marginBottom: 5 }}
      >
        <h4
          className="text-nowrap"
          style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
        >
          DESIGNERS MANAGEMENT
        </h4>
        <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
          <li className="fs-14 breadcrumb-item">
            <Link to="/admin/dashboard">Dashboard</Link>
          </li>
          <li className="fs-14 breadcrumb-item active">Designers</li>
        </ol>
      </div>
      <div className="section-spacing">
        <div className="card designers-management-card">
          <div className="card-header d-flex flex-wrap gap-2 align-items-center justify-content-between">
            <h5
              className="card-title mb-0 flex-grow-1"
              style={{ minWidth: "fit-content" }}
            >
              Queries
            </h5>
            <input
              type="text"
              className="form-control"
              placeholder="Search by Name, Username or Email"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ maxWidth: "250px", height: "28px" }}
            />
          </div>
          <div className="card-body fs-14">
            <DataTable
              columns={columns}
              data={filteredDesigners}
              progressPending={loading}
              pagination
              highlightOnHover
              striped
              responsive
              persistTableHead
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Queries;
