import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { singleImageUpload, updateDesignerData, updateUserData } from "../../api/Api";
import { setUser } from "../../redux/actions/userActions";
import { setDesigner } from "../../redux/actions/designerActions";
import { RiCameraFill } from "react-icons/ri";
import { useLocation } from "react-router-dom";

function ProfileAvatar({ data, setData, token }) {
 const [loading, setLoading] = useState();
 const [profilePicture, setProfilePicture] = useState(data.profileImg || "/assets/images/avatar.png");

 const { pathname } = useLocation();
 const variant = pathname.match(/^\/([^/]+)/)[1];

 useEffect(() => {
  setProfilePicture(
   data.profileImg || "/assets/images/avatar.png"
  );
 }, [data]);

 const fileInputRef = useRef(null);
 const dispatch = useDispatch();
 const [imageSrc, setImageSrc] = useState(profilePicture);

 useEffect(() => {
  setImageSrc(profilePicture);
 }, [profilePicture]);

 const handleFileChange = async (event) => {
  const file = event.target.files[0];
  if (file) {
   setLoading(true);

   const formdata = new FormData();
   formdata.append("image", file);
   const result = await singleImageUpload(formdata);
   if (result.status === "success") {
    const imageUrl = result.data;
    updateProfile(imageUrl);
   } else {
    toast.error("Fail To Upload Image");
   }
   setLoading(false);
  }
 };

 const updateProfile = async (imageUrl) => {
  switch (variant) {
   // USER
   case "user": {
    const result = await updateUserData(
     { profileImg: imageUrl },
     token
    );

    if (result.status === "success") {
     toast.success("Profile Picture Updated Successfully");
     dispatch(setUser(result.data));
     setImageSrc(result.data.profileImg);
     setData(oldData => ({
      ...oldData,
      profileImg: result.data.profileImg,
     }));
    } else {
     toast.error(result.message);
    }
   }
    break;

   // DESIGNER
   case "designer": {
    const result = await updateDesignerData(
     { profileImg: imageUrl },
     token
    );

    if (result.status === "success") {
     toast.success("Profile Picture Updated Successfully");
     dispatch(setDesigner(result.data));
     setImageSrc(result.data.profileImg);
    } else {
     toast.error(result.message);
    }
   }
    break;

   // DEFAULT
   default: return null;
  }
 };

 return (
  <div className="profile-user position-relative d-inline-block mx-auto mb-4 avatar-xl">
   <img
    src={imageSrc}
    className="rounded-circle avatar-xl img-thumbnail user-profile-image"
    alt="user-profile-image"
   />
   <input
    ref={fileInputRef}
    id="profileImg"
    type="file"
    name="profileImg"
    className="profile-img-file-input"
    onChange={handleFileChange}
    disabled={loading}
   />
   <label
    htmlFor="profileImg"
    className="designer-avatar-settings avatar-xs"
   >
    <span className="avatar-title rounded-circle bg-light text-body">
     <RiCameraFill />
    </span>
   </label>
  </div>
 );
}

export default ProfileAvatar;
