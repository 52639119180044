import React from "react";
import ImageModal from "./ImageModal";

function Card({
  id,
  img,
  userimg,
  text,
  hovertext,
  userName,
  portfolio,
  casee = "null",
  designer,
  setActiveTab,
}) {
  const showModal = (modalId) => {
    if (casee === "profile") {
      const modal = new window.bootstrap.Modal(
        document.getElementById(modalId)
      );
      modal.show();
    }
  };

  return (
    <>
      <div
        onClick={() => showModal(`image-modal-${id}`)}
        style={{ cursor: "pointer" }}
      >
        <div className=" border-0">
          <div
            className="inspiring-image inspiring-image-container"
            style={{ height: "200px" }}
          >
            <img src={img} className="card-img-top" alt={text} />
            <div className="inspiring-text">
              <h6>{hovertext}</h6>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 mt-2">
            <img
              width={40}
              height={40}
              src={userimg || "/assets/images/header-avatar.png"}
              className="avatar rounded-5"
              alt=""
            />
            <div>
              <h6 className="mb-0">{userName}</h6>
              <small>{hovertext}</small>
            </div>
          </div>
        </div>
      </div>
      <ImageModal
        setActiveTab={setActiveTab}
        portfolio={portfolio}
        designer={designer}
      />
    </>
  );
}

export default Card;
