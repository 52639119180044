import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAllFeaturedPortfolio,
  portfolioFeaturedStatus,
  portfolioStatus,
} from "../../api/Api";
import DataTable from "react-data-table-component";

function FeaturedPortfolios() {
  const [designerRows, setDesignerRows] = useState([]);
  const [filteredPortfolios, setFilteredPortfolios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleStatus = async (portfolioId) => {
    setLoading(true);
    try {
      const result = await portfolioFeaturedStatus({ portfolioId });
      if (result.status === "success") {
        toast.success(result.message || "Status updated successfully!");
        const res = await getAllFeaturedPortfolio();
        if (res.status === "success") {
          setDesignerRows(res.data);
          setFilteredPortfolios(res.data);
        }
      } else {
        toast.error(result.message || "Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleActiveStatus = async (id) => {
    setLoading(true);
    try {
      const result = await portfolioStatus({ id });
      if (result.status === "success") {
        toast.success(result.message || "Status updated successfully!");
        const res = await getAllFeaturedPortfolio();
        if (res.status === "success") {
          setDesignerRows(res.data);
          setFilteredPortfolios(res.data);
        }
      } else {
        toast.error(result.message || "Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchPortfolios = async () => {
      setLoading(true);
      const res = await getAllFeaturedPortfolio();

      if (res.status === "success") {
        setDesignerRows(res.data);
        setFilteredPortfolios(res.data);
      }
      setLoading(false);
    };

    fetchPortfolios();
  }, []);

  useEffect(() => {
    const result = designerRows.filter((portfolio) => {
      return (
        portfolio.title.toLowerCase().includes(search.toLowerCase()) ||
        portfolio.designer?.name.toLowerCase().includes(search.toLowerCase())
      );
    });
    setFilteredPortfolios(result);
  }, [search, designerRows]);

  const columns = [
    {
      name: "Sr #",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
    },
    {
      name: "Designer Name",
      selector: (row) => row.designer?.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={row.img}
          alt={row.title}
          style={{ width: 50, height: 50, objectFit: "cover" }}
        />
      ),
      sortable: false,
      width: "80px",
    },
    {
      name: "Views",
      selector: (row) => row.views,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <div dangerouslySetInnerHTML={{ __html: row.description }} />
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Featured",
      cell: (row) => (
        <span
          className={`badge ${
            row.featured ? "bg-primary-alt" : "bg-danger-alt"
          } text-white`}
        >
          {row.featured ? "Featured" : "Not Featured"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <span
          className={`badge ${
            row.isActive ? "bg-success-alt" : "bg-danger-alt"
          } text-white`}
        >
          {row.isActive ? "Active" : "Deactivated"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex gap-1">
          <button
            style={{ fontSize: 12.25 }}
            onClick={() => handleActiveStatus(row._id)}
            className={`d-flex align-items-center gap-1 btn btn-sm ${
              row.isActive ? "btn-danger" : "btn-success"
            }`}
          >
            {row.isActive ? "Deactivate" : "Activate"}
          </button>
          <button
            style={{ fontSize: 12.25 }}
            onClick={() => handleStatus(row._id)}
            className={`d-flex align-items-center gap-1 btn btn-sm ${
              row.featured ? "btn-danger" : "btn-primary"
            }`}
          >
            {row.featured ? "Unfeature" : "Feature"}
          </button>
        </div>
      ),
      width: "fit-content",
    },
  ];

  return (
    <section className="dashboard-section admin-featured-portfolios">
      <div
        className="d-flex gap-2 flex-wrap justify-content-between align-items-center"
        style={{ padding: "19px 24px", marginBottom: 5 }}
      >
        <h4
          className="text-nowrap"
          style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
        >
          FEATURED PORTFOLIOS
        </h4>
        <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
          <li className="fs-14 breadcrumb-item">
            <Link to="/admin/dashboard">Dashboard</Link>
          </li>
          <li className="fs-14 breadcrumb-item active">Featured Portfolios</li>
        </ol>
      </div>
      <div className="section-spacing">
        <div className="card featured-portfolios-card">
          <div className="card-header d-flex flex-wrap gap-2 align-items-center justify-content-between">
            <h5
              className="card-title mb-0 flex-grow-1"
              style={{ minWidth: "fit-content" }}
            >
              Featured Portfolios
            </h5>
            <input
              type="text"
              className="form-control"
              placeholder="Search by Title or Designer Name"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ maxWidth: "250px", height: "28px" }}
            />
          </div>
          <div className="card-body fs-14">
            <DataTable
              columns={columns}
              data={filteredPortfolios}
              progressPending={loading}
              pagination
              highlightOnHover
              striped
              responsive
              persistTableHead
              noDataComponent="No Featured Portfolios Available"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturedPortfolios;
