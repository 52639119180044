import React from "react";
import Main from "../components/About/Main";
import Navbar from "../components/common/landing/Navbar";
import Footer from "../components/common/landing/Footer";

function About() {
  return (
    <div>
      <Navbar />
      <Main />
      <Footer />
    </div>
  );
}

export default About;
