import React from "react";

const css = `
body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: ui-sans-serif, system-ui !important;
    background-color: rgb(247,250,252);
    letter-spacing: .05em;
}

#root {
height: 100%;
}

.container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-message {
    display: flex;
    align-items: center;
    color: rgb(160,174,192);
    font-size: 18px;
}

.separator {
    margin: 0 16px;
    width: .5px;
    height: 27px;
    background-color: rgb(203,213,224);
}

@media (max-width: 768px) {
     .container {
     padding: 32px;
      align-items: start;
     }
}`;

function NotFound() {
 return (
  <>
   <style>{css}</style>
   <div className="container">
    <div className="error-message">
     <span className="error-code">404</span>
     <div className="separator"></div>
     <span className="error-text">NOT FOUND</span>
    </div>
   </div>
  </>
 );
}

export default NotFound;
