import { UserActionTypes } from "../constants/actionTypes";

export const setUser = (data) => {
  return {
    type: UserActionTypes.SET_USER,
    payload: data,
  };
};

export const setUserToken = (data) => {
  return {
    type: UserActionTypes.SET_TOKEN,
    payload: data,
  };
};

export const userLogout = () => {
 return {
   type: UserActionTypes.LOGOUT,
 };
};
