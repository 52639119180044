import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateDesignerData } from "../../api/Api";
import { setDesigner } from "../../redux/actions/designerActions";
import { RiCloseFill } from "react-icons/ri";
import SkillProgress from "../../components/common/SkillProgress";
import Loader from "../../components/common/Loader";

const AddSkillsModal = ({ skillsOptions, designerData, setDesignerData }) => {
 const dispatch = useDispatch();

 const [loading, setLoading] = useState();
 const [skillsData, setSkillsData] = useState([]);

 const [suggestedSkills, setSuggestedSkills] = useState([]);

 useEffect(() => {
  setSuggestedSkills(skillsOptions.map(skill => skill.name).filter(skillName =>
   !designerData.skills.some(s => s.name === skillName)
  ));
  // eslint-disable-next-line
 }, [skillsOptions, designerData]);

 const { token: designerToken } = useSelector((state) => state.designerData);

 const handleInputChange = (e, rating, i, skillTaken) => {
  if (rating || i) {
   const newSkillsData = skillsData.slice();
   newSkillsData[i].rating = rating;
   setSkillsData(newSkillsData);
   return;
  }

  if (designerData.skills.concat(skillsData).some(skill => skill.name === e.target.value)) {
   toast.error("Skill already added");
  } else {
   setSkillsData([...skillsData, { name: skillTaken || e.target.value, rating: 1, }]);
  }

  setSuggestedSkills(suggestedSkills.filter(skill =>
   !designerData.skills.concat([...skillsData, { name: skillTaken || e.target.value, rating: 1, }]).some(s => s.name === skill)
  ));

  e.target.value = "";
 };

 const handleSubmit = async (e) => {
  e.preventDefault();

  setLoading(true);

  const result = await updateDesignerData(
   { skills: designerData.skills.concat(skillsData) },
   designerToken
  );

  setSkillsData([]);

  if (result.status === "success") {
   toast.success("Skills Updated Successfully");
   const modal = document.getElementById("profile-setting-add-skills");
   const modalInstance = window.bootstrap.Modal.getInstance(modal);
   modalInstance.hide();
   dispatch(setDesigner(result.data));
   setDesignerData((oldDesignerData) => ({
    ...oldDesignerData,
    skills: designerData.skills.concat(skillsData),
   }));
   setSuggestedSkills(suggestedSkills.filter(skill =>
    !result.data.skills.some(s => s.name === skill)
   ));
  } else {
   toast.error(result.errors?.[0].msg);
  }
  setLoading(false);
 };

 return (
  <>
   <div
    className="modal fade"
    id="profile-setting-add-skills"
    tabIndex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
   >
    <form onSubmit={handleSubmit}>
     <div className="modal-dialog mx-auto" style={{ width: "80%", maxWidth: 700 }}>
      <div className="modal-content container">
       <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
         Add Skills
        </h5>
        <button
         type="button"
         className="btn background-none p-0 position-absolute"
         style={{ right: 16 }}
         data-bs-dismiss="modal"
         aria-label="Close"
         onClick={() => setSkillsData([])}
        ><RiCloseFill /></button>
       </div>
       <div className="modal-body pt-0">
        <div className="row mt-4">
         <div className="flex-grow-1">
          <select
           name="skills"
           className="form-control w-100"
           // disabled={skillsData.length === maxSkills - designerData.skills.length}
           onChange={e => handleInputChange(e)}
          >
           <option value="" disabled selected>
            Select Skill
           </option>
           {/* <option value="Max Skills Reached" disabled selected={skillsData.length === maxSkills - designerData.skills.length} className={`${skillsData.length < maxSkills - designerData.skills.length && "d-none"}`}>
            Maximum skills limit reached.
           </option> */}
           {skillsOptions.map(skill => (
            <option key={skill._id} value={skill.name}>
             {skill.name}
            </option>
           ))}
          </select>
         </div>
        </div>
        <div className="row mt-4">
         {skillsData.map((skill, index) => (
          <div key={`${skill.name}-${index}`} className="col-md-6 mb-4">
           <SkillProgress
            skill={skill}
            onRatingChange={(rating) => handleInputChange(undefined, rating, index)}
            onRemoveSkill={() => {
             setSkillsData(skillsData.filter((_, i) => i !== index));
             setSuggestedSkills([...suggestedSkills, skill.name]);
            }}
            skillsData={designerData.skills.concat(skillsData)}
            editable={true}
           />
          </div>
         ))}
        </div>
        <div className="row mt-4">
         <h6>Suggested Skills:</h6>
         <div className="d-flex flex-wrap gap-2">
          {suggestedSkills.map((skill, index) => (
           <button
            key={index}
            type="button"
            className="btn btn-outline-primary btn-sm suggested-skill-button"
            onClick={(e) => handleInputChange(e, undefined, undefined, skill)}
           >
            {skill}
           </button>
          ))}
         </div>
        </div>
       </div>
       <div className="modal-footer px-0 py-2">
        <button
         type="button"
         className="d-flex align-items-center gap-1 btn btn-sm btn-danger"
         data-bs-dismiss="modal"
         onClick={() => setSkillsData([])}
        >
         Close
        </button>
        <button type="submit" className={`d-flex align-items-center gap-1 btn btn-sm btn-primary`} disabled={loading}>
         {loading ? <Loader /> : "Confirm"}
        </button>
       </div>
      </div>
     </div>
    </form>
   </div>
  </>
 );
};

export default AddSkillsModal;
