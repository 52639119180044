import React from "react";
import Navbar from "../components/common/landing/Navbar";
import Footer from "../components/common/landing/Footer";
import CardDetail from "../components/LandingPage/home/CardDetail";

function ExploreDesignsCard() {
  return (
    <div>
      <Navbar />
      <CardDetail />
      <Footer />
    </div>
  );
}

export default ExploreDesignsCard;
