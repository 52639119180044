import React from 'react'
import Navbar from '../components/common/landing/Navbar'
import Footer from '../components/common/landing/Footer'
import DesignerForgetForm from '../components/ForgetPassword/DesignerForgetForm'
function DesignerForgetPassword() {
  return (
    <div>
      <Navbar/>
      <DesignerForgetForm/>
      <Footer/>
    </div>
  )
}

export default DesignerForgetPassword
