import React from "react";
import Navbar from "../components/common/landing/Navbar";
import Hero from "../components/LandingPage/home/Hero";
import FeaturedDesigner from "../components/LandingPage/home/FeaturedDesigner";
import FeaturedProjects from "../components/LandingPage/home/FeaturedProjects";
// import ExploreDesign from "../components/LandingPage/home/ExploreDesign";
import FindDream from "../components/LandingPage/home/FindDream";
import Footer from "../components/common/landing/Footer";

function Home() {
  return (
    <div>
      <Navbar />
      <Hero />
      <FeaturedDesigner />
      <FeaturedProjects />
      {/* <ExploreDesign /> */}
      <FindDream />
      <Footer />
    </div>
  );
}

export default Home;
