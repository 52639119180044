import React, { useState } from "react";
import { toast } from "react-toastify";
import { contactDesignerForm } from "../../../../api/Api";

function ContactNow({ designer }) {
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleForm = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await contactDesignerForm({
        name,
        lastname,
        email,
        phone,
        message,
        designerId: designer?._id,
      });
      if (result.status === "success") {
        toast.success(result.message || "Form Submit Successful!");
        setName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setMessage("");
      } else {
        toast.error(result.message || "Failed To Submit.");
      }
    } catch (error) {
      toast.error(
        "An error occurred during form submission. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-md-6 col-12 mx-auto">
          <div className="mb-4">
            <h5 className="fw-semibold text-center">Contact Now</h5>
          </div>
          <form onSubmit={handleForm}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    className="form-control"
                    placeholder="Name"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    value={lastname}
                    className="form-control"
                    placeholder="Last Name"
                    required
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    className="form-control"
                    placeholder="Email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    value={phone}
                    className="form-control"
                    placeholder="Phone Number"
                    required
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    name="message"
                    value={message}
                    placeholder="leave a message"
                    rows="5"
                    required
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn  w-100 py-2 custom-btn text-white mb-3"
                  disabled={loading}
                >
                  {loading ? "LOADING..." : "Send Message"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactNow;
