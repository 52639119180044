import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { designerforgotPassword } from "../../api/Api";
import { toast } from "react-toastify";

function DesignerForgetForm() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleForget = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const result = await designerforgotPassword(email);
      if (result.status === "success") {
        toast.success(result.message || "Email Send Successful!");
        setEmail("");
        navigate("/login");
      } else {
        toast.error(result.message || "Failed to Reset.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="d-flex justify-content-center align-items-center pt-3">
      <div className="text-center mt-5 designer-login-card">
        <div className="row">
          <div className="col-md-6 d-none d-md-block">
            <img
              className="w-100 contact-img "
              src="/assets/images/login-designer.jpg"
              alt="Designer Login"
            />
          </div>
          <div className="col-md-6 my-auto">
            <img
              className="pt-2"
              width={180}
              src="/assets/images/authlogo.png"
              alt="logo"
            />
            <div className="fs-2">Reset Password</div>

            <div className="mx-4">
              <small className="mb-3">
                Enter your email address to reset your password.
              </small>
              <form onSubmit={handleForget}>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    value={email}
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="floatingInput">Email address</label>
                </div>
                <button
                  type="submit"
                  className="btn rounded-5 w-100 py-2 custom-btn text-white mb-3"
                  disabled={loading}
                >
                  {loading ? "LOADING..." : "Reset Password"}
                </button>
              </form>

              <div className="pb-3">
                <small className="text-muted ">
                  Login To Your Account
                  <Link
                    to={"/login"}
                    className="text-decoration-underline text-muted"
                  >
                    {" "}
                    Click Here
                  </Link>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesignerForgetForm;
