import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";

function DesignerProfileCard({ designer }) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("default", { month: "long", year: "numeric" });
  };

  return (
    <div className="designer rounded-3 border-0 bg-white shadow mb-4 ">
      <div className="designer-cover">
        <img
          src={designer?.coverImg || "/assets/images/16x9-placeholder.jpg"}
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
          alt="Cover"
        />
      </div>
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="profile bg-dark" style={{ borderRadius: "50%" }}>
            <img
              src={designer?.profileImg || "/assets/images/header-avatar.png"}
              alt="Profile"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="m-3 profile-text text-center">
              <h1 className="m-0">{designer?.name}</h1>
              <p
                className="text-muted m-0 location"
                style={{ fontSize: "16px !important" }}
              >
                {designer?.city},{designer?.country}
              </p>
              <p className="text-black mt-2" style={{ fontSize: "18px" }}>
                {designer?.aboutMe}
              </p>
            </div>
          </div>
          <div className="col-lg-12 text-center">
            <small className="text-muted">
              {`Member since ${formatDate(designer?.createdAt)}`}
            </small>

            <div className="d-flex justify-content-center gap-2 fs-4 mb-2">
              {designer?.portfolioSocials?.twitter && (
                <Link
                  to={designer.portfolioSocials.twitter}
                  target="_blank"
                  className="text-main"
                >
                  <small>
                    <FaTwitter />
                  </small>
                </Link>
              )}
              {designer?.portfolioSocials?.facebook && (
                <Link
                  to={designer.portfolioSocials.facebook}
                  target="_blank"
                  className="text-main"
                >
                  <small>
                    <FaFacebook />
                  </small>
                </Link>
              )}
              {designer?.portfolioSocials?.instagram && (
                <Link
                  to={designer.portfolioSocials.instagram}
                  target="_blank"
                  className="text-main"
                >
                  <small>
                    <FaInstagram />
                  </small>
                </Link>
              )}
              {designer?.portfolioSocials?.pinterest && (
                <Link
                  to={designer.portfolioSocials.pinterest}
                  target="_blank"
                  className="text-main"
                >
                  <small>
                    <FaPinterest />
                  </small>
                </Link>
              )}
            </div>

            <Link to={`/designer-profile/${designer?._id}`}>
              <button
                type="submit"
                className="btn rounded my-3 custom-btn text-white"
              >
                <small>Visit Profile</small>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesignerProfileCard;
