import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { updatePortfolio, singleImageUpload, getDesignerPortfolioById } from "../../api/Api";
import Loader from "../../components/common/Loader";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { BlockQuote, Bold, ClassicEditor, CodeBlock, Essentials, Heading, Italic, List, MediaEmbed, Paragraph, Table, Underline, Undo, Link as LinkPlugin } from "ckeditor5";
import ImageGallery from "../../components/common/ImageGallery";

function EditPortfolio() {
 const navigate = useNavigate();
 const [loading, setLoading] = useState();
 const [isFetching, setIsFetching] = useState();
 const [fileLoading, setFileLoading] = useState();
 const [portfolioData, setPortfolioData] = useState({});

 const { token: designerToken } = useSelector((state) => state.designerData);

 const { id } = useParams();

 useEffect(() => {
  const fetchPortfolioById = async () => {
   setIsFetching(true);
   const result = await getDesignerPortfolioById(id, designerToken);

   if (result.status === "success") {
    setPortfolioData(result.data);
   }
   setIsFetching(false);
  };

  fetchPortfolioById();
  // eslint-disable-next-line
 }, []);

 const handleInputChange = async (e) => {
  const { name, value } = e.target;

  setPortfolioData((oldPortfolioData) => ({
   ...oldPortfolioData,
   [name]: value,
  }));
 };

 const handleFileChange = async (event) => {
  const file = event.target.files[0];
  if (file) {
   setFileLoading(true);

   const formdata = new FormData();
   formdata.append("image", file);
   const result = await singleImageUpload(formdata);
   if (result.status === "success") {
    const fileUrl = result.data;
    setPortfolioData((oldPortfolioData) => ({
     ...oldPortfolioData,
     img: fileUrl,
    }));
    toast.success("Image Uploaded Successfully");
   } else {
    toast.error("Fail To Upload Image");
   }
   setFileLoading(false);
  }
 };

 const handleSubmit = async (e) => {
  e.preventDefault();

  if (!portfolioData.description.trim()) {
   toast.error("Description is required.");
   return;
  }

  setLoading(true);

  const result = await updatePortfolio(
   { ...portfolioData, portfolioId: id },
   designerToken
  );

  if (result.status === "success") {
   toast.success("Portfolio Added Successfully");
   navigate("/designer/portfolio");
  } else {
   toast.error(result.errors?.[0].msg ?? result.message);
  }
  setLoading(false);
 };

 return (
  <section className="dashboard-section user-security-settings">
   <div className="d-flex gap-2 flex-wrap justify-content-between" style={{ padding: "19px 24px", marginBottom: 5 }}>
    <h4 className="text-nowrap" style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}>EDIT PROJECT</h4>
    <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
     <li className="fs-14 breadcrumb-item"><Link to="/designer/dashboard">Dashboard</Link></li>
     <li className="fs-14 breadcrumb-item"><Link to="/designer/portfolio">Manage Portfolio</Link></li>
     <li className="fs-14 breadcrumb-item active">Edit Project</li>
    </ol>
   </div>
   <form className="section-spacing" onSubmit={handleSubmit}>
    <div className="card">
     <div className="card-header">
      <h5 className="card-title mb-0">Edit Project</h5>
     </div>
     <div className="card-body p-4">
      <div className="row">
       <div className="col-12">
        <div className="row">
         <div className="col-12 col-lg-6 mb-3">
          <div className="mb-3">
           <label htmlFor="title" className="form-label">
            Project Title
           </label>
           <input
            id="title"
            type="text"
            className="form-control fs-14"
            name="title"
            onChange={handleInputChange}
            value={portfolioData.title}
            disabled={isFetching}
            placeholder={isFetching ? "Loading..." : "Project Title"}
            required
           />
          </div>
          <div className="mb-3">
           <label htmlFor="img" className="form-label">Banner Image</label> <small>(png, jpg, webp, Less than 2Mb)</small>
           <input
            type="file"
            id="img"
            className="form-control fs-14"
            name="img"
            onChange={handleFileChange}
            accept="image/*"
            disabled={loading || fileLoading || isFetching}
           />
          </div>
         </div>
         <div className="col-12 col-lg-6 mb-3">
          <label className="form-label">
           Banner Preview
          </label>
          <img
           src={portfolioData.img || "/assets/images/image-placeholder.png"}
           alt="image-placeholder"
           style={{
            width: "100%",
            maxHeight: "200px",
            objectFit: "cover"
           }}
          />
         </div>
        </div>
       </div>

       <div className="col-12 mb-3">
        <ImageGallery
         clientData={portfolioData}
         setClientData={setPortfolioData}
         token={designerToken}
         mode="portfolio-edit"
        />
       </div>

       <div className="col-12 mb-3">
        <label htmlFor="description" className="form-label">Description</label>
        <CKEditor
         editor={ClassicEditor}
         name="description"
         data={portfolioData.description}
         onChange={(_, editor) => {
          const value = editor.getData();
          setPortfolioData((oldPortfolioData) => ({
           ...oldPortfolioData,
           description: value,
          }));
         }}
         config={{
          toolbar: {
           items: [
            "heading",
            "bold",
            "italic",
            "underline",
            "bulletedList",
            "numberedList",
            "blockQuote",
            "codeBlock",
            "insertTable",
            "link",
            "mediaEmbed",
            "|",
            "undo",
            "redo",
           ],
          },
          plugins: [
           Bold, Essentials, Italic, Paragraph, Undo, Underline, Heading, LinkPlugin, List, BlockQuote, CodeBlock, Table, MediaEmbed,
          ],
         }}
         disabled={isFetching}
        />
       </div>
      </div>
      <button type="submit" className={`ms-auto d-flex align-items-center gap-1 btn btn-primary`} disabled={loading || fileLoading || isFetching}>
       {loading ? <Loader /> : "Save Changes"}
      </button>
     </div>
    </div>
   </form>
  </section>
 );
}

export default EditPortfolio;
