import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { adminReducer } from "./adminReducer";
import { designerReducer } from "./designerReducer";
import { generalStateReducer } from "./generalStateReducer";

const reducers = combineReducers({
 userData: userReducer,
 designerData: designerReducer,
 adminData: adminReducer,
 generalState: generalStateReducer,
});

export default reducers

