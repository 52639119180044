import React, { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { getlatestFeaturedPortfolio } from "../../../api/Api";
import { Link } from "react-router-dom";

function FeaturedProjects() {
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPortfolio = async () => {
      setLoading(true);
      const res = await getlatestFeaturedPortfolio();

      if (res.status === "success") {
        setPortfolios(res.data);
      }
      setLoading(false);
    };

    fetchPortfolio();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center my-5">
        <h2>Loading...</h2>
      </div>
    );
  }

  return (
    <div className="container my-5">
      <div className="text-center mb-5">
        <h1>Featured Portfolio</h1>
        <p>Express your individuality with exquisite works of art.</p>
      </div>
      {portfolios.length > 0 ? (
        <div className="edu-blog-area edu-section-gap">
          <div className="wrapper">
            <div className="container eduvibe-animated-shape">
              <div className="row g-5 align-items-center">
                <div className="col-lg-12">
                  <div
                    className="gallery-grid-wrapper mesonry-list overflow-hidden section-title"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <ResponsiveMasonry
                      columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                    >
                      <Masonry gutter="1.5rem">
                        {portfolios?.map((portfolio) => (
                          <Link
                            key={portfolio._id}
                            to={`/designer-profile/${portfolio?.designer?._id}`}
                            state={{ fromHomepage: true }}
                          >
                            <img
                              src={portfolio.img}
                              width="100%"
                              style={{ objectFit: "cover" }}
                              alt=""
                            />
                          </Link>
                        ))}
                      </Masonry>
                    </ResponsiveMasonry>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center my-5">
          <h4>No Featured Portfolio Available</h4>
        </div>
      )}
    </div>
  );
}

export default FeaturedProjects;
