import React from 'react'
import Navbar from '../components/common/landing/Navbar'
import Footer from '../components/common/landing/Footer'
import Main from '../components/Contactus/Main'

function Contactus() {
  return (
    <div>
      <Navbar/>
      <Main/>
      <Footer/>
    </div>
  )
}

export default Contactus
