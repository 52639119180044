import React, { createContext, useContext, useState } from "react";

const SidebarContext = createContext();

function SidebarContextProvider({ children }) {
 const [sidebarOpened, setSidebarOpened] = useState(true);

 return (
  <>
   <SidebarContext.Provider value={{ sidebarOpened, setSidebarOpened }}>
    {children}
   </SidebarContext.Provider>
  </>
 )
}

export const useSidebarContext = () => {
 return useContext(SidebarContext);
}

export default SidebarContextProvider
