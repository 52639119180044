import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { getDashboardSidebarItems } from "../../../utils/data/getDashboardSidebarItems";
import { useSidebarContext } from "../../../contexts/dashboardContext";
import { useDispatch, useSelector } from "react-redux";
import { adminLogout } from "../../../redux/actions/adminActions";
import { userLogout } from "../../../redux/actions/userActions";
import { designerLogout } from "../../../redux/actions/designerActions";
import { setIsTokenInitialized } from "../../../redux/actions/generalStateActions";

function DashboardLayout() {
  const dispatch = useDispatch();

  const dropdownRef = useRef(null);
  const [dropDownOpened, setDropDownOpened] = useState(false);
  const { sidebarOpened, setSidebarOpened } = useSidebarContext();
  const screen = useWindowSize();

  const user = useSelector((state) => state.userData.user);
  const designer = useSelector((state) => state.designerData.designer);

  const { pathname } = useLocation();
  const variant = pathname.match(/^\/([^/]+)/)[1];

  useEffect(() => {
   dispatch(setIsTokenInitialized(false));
   // eslint-disable-next-line
  }, [])

  function getToggleButtonAttributes() {
    if (screen.width < 768) {
      return {
        "data-bs-toggle": "offcanvas",
        "data-bs-target": "#offcanvasExample",
        "aria-controls": "offcanvasExample",
      };
    } else {
      return {
        onClick: () => setSidebarOpened(!sidebarOpened),
      };
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.closest("#page-header-user-dropdown")
      ) {
        setDropDownOpened(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    switch (variant) {
      case "user":
        dispatch(userLogout());
        break;
      case "designer":
        dispatch(designerLogout());
        break;
      case "admin":
        dispatch(adminLogout());
        break;
      default:
        return null;
    }
  };

  const dashboardSidebarItems = getDashboardSidebarItems(
    variant,
    sidebarOpened
  );

  return (
    <>
      <section className="dashboard-layout d-flex">
        {screen.width < 768 ? (
          <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
            style={{ width: 250 }}
          >
            <aside
              className={`navbar-menu`}
              style={{ width: "100%", boxShadow: "none", paddingTop: 10 }}
            >
              <ul className="simplebar-content" style={{ padding: "0px" }}>
                <li className="menu-title">Menu</li>
                {dashboardSidebarItems.map((item) => (
                  <React.Fragment key={item.link}>
                    <DashboardSidebarItems
                      item={item}
                      windowWidth={screen.width}
                      sidebarOpened={sidebarOpened}
                    />
                  </React.Fragment>
                ))}
              </ul>
            </aside>
          </div>
        ) : (
          <aside className={`navbar-menu ${!sidebarOpened && "collapsed"}`}>
            <div className="mx-auto">
              {sidebarOpened ? (
                <Link to="/">
                  <span className="logo-lg">
                    <img
                      className="sidebar-logo"
                      src="/assets/images/logo-trans.png"
                      alt=""
                      height="100"
                    />
                  </span>
                </Link>
              ) : (
                <Link to="/">
                  <span className="logo-sm">
                    <img
                      className="sidebar-logo"
                      src="/assets/images/logo.png"
                      style={{ marginBlock: 19 }}
                      alt=""
                      height="32"
                    />
                  </span>
                </Link>
              )}
            </div>
            <div>
              <ul className="simplebar-content" style={{ padding: "0px" }}>
                {sidebarOpened && <li className="menu-title">Menu</li>}
                {dashboardSidebarItems.map((item) => (
                  <React.Fragment key={item.link}>
                    <DashboardSidebarItems
                      item={item}
                      windowWidth={screen.width}
                      sidebarOpened={sidebarOpened}
                    />
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </aside>
        )}
        <main className="dashboard-main flex-grow-1 d-flex flex-column">
          <header className="dashboard-header">
            <button
              type="button"
              className="btn btn-sm p-0 d-flex align-items-center"
              {...getToggleButtonAttributes()}
            >
              <span
                className={`hamburger-icon ${
                  screen.width < 768 ? "open" : !sidebarOpened ? "open" : null
                }`}
              >
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
            <div className="topbar-user">
              <button
                type="button"
                className="btn rounded-0"
                id="page-header-user-dropdown"
                onClick={() => setDropDownOpened(!dropDownOpened)}
              >
                <span className="d-flex align-items-center">
                  <img
                    className="rounded-circle"
                    width={32}
                    height={32}
                    src={
                      variant === "user"
                        ? user?.profileImg && user.profileImg.trim() !== ""
                          ? user.profileImg
                          : "/assets/images/header-avatar.png"
                        : variant === "designer"
                        ? designer?.profileImg &&
                          designer.profileImg.trim() !== ""
                          ? designer.profileImg
                          : "/assets/images/header-avatar.png"
                        : "/assets/images/header-avatar.png"
                    }
                    style={{ objectFit: "cover" }}
                    alt="Header Avatar"
                  />
                  <span className="text-start ms-xl-2">
                    <span
                      className="d-none d-xl-inline-block ms-1 fw-medium"
                      style={{ fontSize: 14 }}
                    >
                      {variant === "user"
                        ? ` ${user?.name}`
                        : variant === "designer"
                        ? ` ${designer?.name}`
                        : "You"}
                    </span>
                    <span
                      className="d-none d-xl-block ms-1 text-muted"
                      style={{ fontSize: 12 }}
                    >
                      {variant === "user"
                        ? "User/Client"
                        : variant === "designer"
                        ? "Designer"
                        : "Admin"}
                    </span>
                  </span>
                </span>
              </button>
              <div
                ref={dropdownRef}
                className={`user-dropdown ${dropDownOpened && "show"}`}
              >
                <h6
                  className="fw-semibold"
                  style={{
                    fontSize: 12.25,
                    fontWeight: 600,
                    padding: "8px 20px 0",
                  }}
                >
                  Welcome
                  {variant === "user"
                    ? ` ${user?.name}`
                    : variant === "designer"
                    ? ` ${designer?.name}`
                    : " Admin"}
                  !
                </h6>
                {variant !== "admin" && (
                  <Link
                    onClick={() => setDropDownOpened(false)}
                    className="user-dropdown-item d-flex gap-2 align-items-center"
                    to={
                      variant === "user"
                        ? "/user/profile"
                        : variant === "designer"
                        ? "/designer/profile"
                        : null
                    }
                  >
                    <FaUserCircle color="#878a99" />
                    <button type="button" className="btn p-0 fs-14">
                      Profile
                    </button>
                  </Link>
                )}
                <button
                  type="button"
                  className="btn rounded-0 w-100 fs-14 user-dropdown-item d-flex gap-2 align-items-center"
                  onClick={() => {
                    setDropDownOpened(false);
                    handleLogout();
                  }}
                >
                  <RiLogoutBoxRLine color="#878a99" /> Logout
                </button>
              </div>
            </div>
          </header>

          <Outlet />

          <footer className="dashboard-footer d-flex justify-content-between">
            <div>{new Date().getFullYear()} © I am a designer.</div>
          </footer>
        </main>
      </section>
    </>
  );
}

export default DashboardLayout;

function DashboardSidebarItems({ item, windowWidth, sidebarOpened }) {
  if (windowWidth < 768) {
    return (
      <li
        className="dashboard-sidebar-item"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample"
      >
        <NavLink to={item.link}>
          {item.icon}
          {<span className="link-text">{item.text}</span>}
        </NavLink>
      </li>
    );
  } else {
    return (
      <li className="dashboard-sidebar-item">
        <NavLink to={item.link}>
          {item.icon}
          {<span className="link-text">{item.text}</span>}
        </NavLink>
        {!sidebarOpened && (
          <div className="sidebar-item-tooltip">{item.text}</div>
        )}
      </li>
    );
  }
}
