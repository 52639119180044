import React from "react";
import { Link, useLocation } from "react-router-dom";

function PortfolioDetails() {
  const location = useLocation();
  const { portfolio } = location.state || {};
  return (
    <div className="dashboard-section admin-security-settings">
      <div
        className="d-flex gap-2 flex-wrap justify-content-between"
        style={{ padding: "19px 24px", marginBottom: 5 }}
      >
        <h4
          className="text-nowrap"
          style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
        >
          PORTFOLIO DETAILS
        </h4>
        <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
          <li className="fs-14 breadcrumb-item">
            <Link to="/admin/dashboard">Dashboard</Link>
          </li>
          <li className="fs-14 breadcrumb-item active">
            <Link to={"/admin/portfolio"}>Back to Portfolio Management</Link>
          </li>
        </ol>
      </div>
      <div className="section-spacing">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header ">
              <div className="row">
                <div className="col-lg-4 col-6">
                  <h5 className="card-title mb-0">Profile Image</h5>
                </div>
                <div className="col-lg-8 col-6">
                  <h5 className="card-title mb-0">User Details</h5>
                </div>
              </div>
            </div>
            <div className="card-body p-4">
              <div className="row">
                <div className="col-lg-4 col-12 text-center text-lg-left mb-4 mb-md-0">
                  <img
                    src="/assets/images/header-avatar.png"
                    width={"50%"}
                    alt=""
                    className="mx-auto d-block"
                  />
                </div>
                <div className="col-lg-4">
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold">Title</small>
                        </div>
                        <div className="col-7">
                          <small className="text-danger ">
                            {portfolio?.title || "N/A"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold">Bio</small>
                        </div>
                        <div className="col-7">
                          <small className="text-muted ">
                            {portfolio?.createdAt || "N/A"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold">Full Name</small>
                        </div>
                        <div className="col-7">
                          <small className="text-danger ">
                            {portfolio?.designer?.name || "N/A"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-5">
                          <small className="fw-bold">Email Address</small>
                        </div>
                        <div className="col-7">
                          <small className="text-muted ">
                            {portfolio?.designer?.email || "N/A"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-header">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <h5 className="card-title mb-3">Description</h5>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: portfolio?.description,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="card-header mb-5">
            <div className="row">
              <div className="col-lg-12 col-12">
                <h5 className="card-title mb-3">Images</h5>
              </div>
              <div>
                <img width={"100%"} src={portfolio?.img || "N/A"} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortfolioDetails;
