import React from "react";
import Navbar from "../components/common/landing/Navbar";
import Footer from "../components/common/landing/Footer";
import RegisterScreen from "../components/Register/RegisterScreen";

function DesignerRegister() {
  return (
    <div>
      <Navbar />
      <RegisterScreen />
      <Footer />
    </div>
  );
}

export default DesignerRegister;
