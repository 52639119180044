import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { commentPost, getAllComments, deleteComment } from "../../../api/Api";
import { MdDelete } from "react-icons/md";

function Comments({ id }) {
  const { token: userToken } = useSelector((state) => state.userData);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [allComments, setAllComments] = useState([]);

  const fetchComments = async () => {
    setLoading(true);
    const res = await getAllComments(id);

    if (res.status === "success") {
      setAllComments(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchComments();
    // eslint-disable-next-line
  }, [id]);

  const handleComment = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await commentPost(
        { portfolioId: id, comments: comment },
        userToken
      );
      if (result.status === "success") {
        toast.success(result.message || "Saved Successfully!");
        setComment("");
        fetchComments();
      } else {
        toast.error(result.message || "Failed to Save.");
      }
    } catch (error) {
      toast.error("An error occurred during Comment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (commentId) => {
    setLoading(true);
    try {
      const result = await deleteComment(commentId, userToken);
      if (result.status === "success") {
        toast.success(result.message || "Deleted Successfully!");
        fetchComments();
      } else {
        toast.error(result.message || "Failed to Delete.");
      }
    } catch (error) {
      toast.error("An error occurred during deleting. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h4 className="mt-5">Comments</h4>
      {allComments.length > 0 ? (
        allComments.map((comment) => (
          <div key={comment._id} className="d-flex justify-content-between mt-3 text-muted">
            <div>
              <span className="fw-bold me-2">{comment.userId.name}:</span>
              {comment.comments}
            </div>
            <div>
              <button
                type="button"
                className="btn text-decoration-none"
                onClick={() => handleDelete(comment._id)}
                disabled={loading}
              >
                <MdDelete color="red" size={18} />
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className="mt-3 text-muted">
          No comments yet. Add on to start the conversation.
        </div>
      )}
      <div className="mt-auto">
        {userToken ? (
          <>
            <div className="mt-5">What do you think?</div>
            <div className="d-flex mt-3 align-items-center">
              <img
                className="rounded-5 mt-1"
                width={40}
                height={40}
                src={"/assets/images/header-avatar.png"}
                alt="User Avatar"
                style={{ objectFit: "cover" }}
              />
              <form
                onSubmit={handleComment}
                className="ms-2 w-100 d-flex align-items-center"
              >
                <input
                  style={{
                    fontSize: "16px",
                    position: "relative",
                    paddingRight: "90px",
                  }}
                  placeholder="Type Comment Here"
                  className="form-control py-2"
                  type="text"
                  name="comments"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  disabled={loading}
                />
                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      right: "30px",
                      zIndex: "10",
                    }}
                    disabled={loading}
                  >
                    {loading ? "Saving..." : "Post"}
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <div className="mt-5 text-muted">Login to comment.</div>
        )}
      </div>
    </div>
  );
}

export default Comments;
