import React from "react";
import { useNavigate } from "react-router-dom";

function OtherProjects({ designer }) {
  const navigate = useNavigate();

  const handleProjectClick = (projectId) => {
    navigate(`/project/${projectId}`);
  };

  return (
    <>
      {designer.additionalPortfolios?.length > 0 && (
        <div className="designer rounded-3 border-0 bg-white shadow p-3 mb-4">
          <span className="text-center mb-3">
            Other Projects By{" "}
            <span className="text-main">
              {designer?.currentPortfolio?.designerId?.name}
            </span>
          </span>
          {designer.additionalPortfolios.map((project, index) => (
            <div
              key={index}
              className="mb-4 card shadow"
              onClick={() => handleProjectClick(project._id)}
              style={{ cursor: "pointer" }}
            >
              <img src={project.img} width={"100%"} alt={project.title} />
              <div className="mt-3 text-center">
                <h5>{project.title}</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: project?.description,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default OtherProjects;
